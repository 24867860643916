import { Button, Divider, Form, Input, InputNumber, message, Radio, Select, Space, TreeSelect, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { META } from '../../../../utils/constant';
import { ListAccountantBankRequest, ListAccountingAccountRequest, ListUnitNoDispatchRequest, SearchProductsWarehouseNoDispatchRequest, StoreUnitNoDispatchRequest } from '../../../../actions/indexWeb';
import { DATA_PERCENT_VAT } from '../../../../constants/DefaultData';
import {

    PlusOutlined,
} from '@ant-design/icons';
import { filAcounts, formatMN1, formatNumber, handleNumberInput, parseNumberV_ } from '../../../../service';
const Index = ({ formRef, listDefaultAccountFil, setTotal,listSuplier }) => {

    const [tab, setTab] = useState(1);
    const [dataDetail1, setdataDetail1] = useState([
        { code_pro: "", name_pro: "", tkno: "", tkco: "", unit_id: "", amount: "", price: "", total_price: "", percent_discount: "", total_discount: "", expense: "", total: "", note_vat: "", percent_vat: "", vat_price: "", account_vat: "" },
    ]);
    const [listAccount, setListAccount] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [listUnits, setListUnits] = useState([]);

    const [listTKno, setListTKno] = useState([]);
    const [listTKco, setListTKco] = useState([]);
    const [listTKvat, setListTKvat] = useState([]);

    const [meta, setMeta] = useState({ ...META });
    const [listBanks, setListBanks] = useState([]);

    const newRowRef = useRef(null);
    const tableRef = useRef(null);
    const getListBankTemplate = async () => {
        const response = await ListAccountantBankRequest({
          limit: meta.per_page * 100,
          page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
          if (response.data) {
    
            if (response.data.data) {
    
              setListBanks(response.data.data);
            }
          }
        }
    
    
      }
    const addRow = () => {
        setdataDetail1([...dataDetail1, { code_pro: "", name_pro: "", tkno: "", tkco: "", unit_id: "", amount: "", price: "", total_price: "", percent_discount: "", total_discount: "", expense: "", total: "", note_vat: "", percent_vat: "", vat_price: "", account_vat: "" },])

    }
    const deleteRow = (i) => {
        if (dataDetail1.length <= 1) {
            return;
        }
        const newrow = dataDetail1.filter((value, index) => {
            return index != i;
        })
        setdataDetail1(newrow);
    }
    const handleKeyDown = (e, isLastCell = false) => {


        // console.log(e);
        if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
            addRow();
        }

        if (!isLastCell && (e.key === 'Enter')) {
            e.preventDefault();
            addRow();

        }
    };
    useEffect(() => {

        processListAccount();

        if (newRowRef.current) {

            newRowRef.current.focus();
        }
    }, [dataDetail1 && dataDetail1.length]);

    const setTotal_ = () => {
        const total_ = {
            totalPrice: 0,
            totalVat: 0,
            totalDiscount: 0,
            total: 0,

        };
        if (formRef.current) {
            const formvalue = formRef.current.getFieldsValue();
            dataDetail1.map((value, index) => {
                console.log(formvalue["d-total-" + index]);
                total_.totalPrice+=parseNumberV_(formvalue["d-total-" + index]);
                total_.totalVat+=parseNumberV_(formvalue["d-vat_price-" + index]);
                // total_.totalPrice += Number(formvalue["d-total-" + index].toString().replace(/\D/g, ''));
                // total_.totalVat += Number(formvalue["d-vat_price-" + index].toString().replace(/\D/g, ''));
                // total_.totalDiscount += Number(formvalue["d-total_discount-" + index].toString().replace(/\D/g, ''));

            })
        }

        // total_.total = total_.totalPrice + total_.totalVat;

        setTotal({ ...total_ })
    }
    useEffect(() => {
        scrollToElement(tab)
        getListAccount();
        getListProducts();
        getListUnits();
        getListBankTemplate();
        return () => {

        };
    }, []);
    const scrollToElement = (value) => {

        setTab(value)
        const element = document.getElementById("tab" + value); // Lấy phần tử dựa trên ID
        console.log(value, element);

        if (element && tableRef.current.contains(element)) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    const storeUnit = async (e, type) => {
        const formvalues = formRef.current.getFieldsValue();

        const response = await StoreUnitNoDispatchRequest({
            "name": formvalues[type],
            "code": "",
            "type": "0",
            "hidden": "0",
            "deleted": "0"
        });
        if (response.code == 200) {
            if (response.message) {
                message.success(response.message);
            } else {
                message.success("Cập nhật thành công");
            }
            getListUnits();

        } else {
            if (response.message) {
                if (typeof response.message == "string") {
                    message.error(response.message);
                } else {
                    (Object.values(response.message) || []).map((value, index) => {
                        message.error(value);
                    })
                }

            } else {
                message.warning("Cập nhật không thành công");
            }
        }
    }
    const getListUnits = async () => {
        const response = await ListUnitNoDispatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "name", sort: "ASC"
        });
        console.log("getListUnitsgetListUnits", response);
        if (response.code == 200) {
            if (response.data) {

                if (response.data.data) {

                    setListUnits(response.data.data);
                }
            }
        }


    }
    const getListProducts = async () => {
        const response = await SearchProductsWarehouseNoDispatchRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListProducts(response.data.data);
                }
            }
        }


    }
    const getListAccount = async () => {
        const response = await ListAccountingAccountRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListAccount(response.data.data);
                }
            }
        }


    }

    // Hàm tìm kiếm dữ liệu
    const filterTreeNode = (inputValue, treeNode) => {
        const { title, description, code } = treeNode;
        // console.log(treeNode.props.data);

        return (
            title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
            description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
            code.toString().includes(inputValue) // Tìm kiếm theo code
        );
    };



    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (e) => {


        if (e.target.tagName != "TH") return;
        setIsDragging(true);
        setStartX(e.pageX - tableRef.current.offsetLeft);
        setScrollLeft(tableRef.current.scrollLeft);

    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - tableRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
        tableRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };
    const validateInputPrice = (e) => {
        const allowedKeys = [
            "Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", // Phím điều hướng và xoá
        ];
        const allowedCharacters = "0123456789,.";

        // Cho phép các phím điều hướng
        if (allowedKeys.includes(e.key)) return;

        // Ngăn các ký tự không hợp lệ
        if (!allowedCharacters.includes(e.key)) {
            e.preventDefault();
        }
    };
    const handleMoneyChange = (e, name, index) => {
        let inputValue = "";
        if (e.target.value) {
            inputValue = e.target.value // Loại bỏ ký tự không phải số
        } else {
            inputValue = e.toString() // Loại bỏ ký tự không phải số
        }
        const formattedValue= handleNumberInput(inputValue);

        formRef.current.setFieldsValue({
            [`${name}-${index}`]: formattedValue,
        });

    };
    const handleProcessMoneyChange = (e, name, index) => {

        if (formRef.current) {
            // switch (name) {
            //     case "":

            //         break;

            //     default:
            //         break;
            // }
            const values = formRef.current.getFieldsValue();
            const price = parseNumberV_(values["d-total-" + index]) ?? 0;
            const amount = values["d-amount-" + index] ?? 0;
       
            const percent_vat = values["d-percent_vat-" + index] ?? 0;

          
            const vat_price = price*(percent_vat/100)
           
            
            formRef.current.setFieldsValue({ ["d-vat_price-" + index]: formatNumber(vat_price) })
        }
    };
    useEffect(() => {
        //  console.log("listDefaultAccountFil",listDefaultAccountFil);

        processListAccount();
        return () => {

        };
    }, [listDefaultAccountFil]);

    const processListAccount = async () => {
        if (listDefaultAccountFil) {
            const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
            const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);
            const listTKvat_ = await filAcounts(listDefaultAccountFil, "tk-thue-gtgt", listAccount);
            console.log("listTKno_listTKno_listTKno_ ", listTKno_, listTKco_, listTKvat_);

            setListTKno(listTKno_);
            setListTKco(listTKco_);
            setListTKvat(listTKvat_);



        }
    }
    useEffect(() => {
        if (formRef.current) {
            const formvalues = formRef.current.getFieldsValue();
            dataDetail1.map((row, index) => {
                formRef.current.setFieldsValue({ [`d-tkno-${index}`]: "", [`d-tkco-${index}`]: "", [`d-account_vat-${index}`]: "", })
                if (!formvalues[`d-tkno-${index}`]) {
                    if (listTKno.default) {
                        formRef.current.setFieldsValue({ [`d-tkno-${index}`]: listTKno.default })


                    }
                }
                if (!formvalues[`d-tkco-${index}`]) {
                    if (listTKco.default) {
                        formRef.current.setFieldsValue({ [`d-tkco-${index}`]: listTKco.default })

                    }
                }
                if (!formvalues[`d-account_vat-${index}`]) {
                    if (listTKvat.default) {
                        formRef.current.setFieldsValue({ [`d-account_vat-${index}`]: listTKvat.default })

                    }
                }
            });
        }
        return () => {

        };
    }, [listTKco, listTKno, listTKvat]);
    const changeSuplier = (e,index) => {

        const suplier = listSuplier.filter((value, index) => {
          return value.code == e;
        })
        if (suplier[0]) {
          if (formRef.current) {
            formRef.current.setFieldsValue({ [`d-suplier_name-${index}`]: suplier[0].name})
            
          }
        }
    
    
      }
    return (
        <div>
            <div className={`row`}>
                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                    <div className=' bg-white p-2'>
                        <Radio.Group
                            options={[{ value: 1, label: "Hàng tiền" }, { value: 2, label: "Thuế" }]}
                            onChange={(e) => {
                                const value = e.target.value;
                                scrollToElement(value)
                            }}
                            value={tab}
                            optionType="button"
                            buttonStyle="solid"
                            checked
                        />
                        <div className="table-responsive" style={{ height: "300px" }} ref={tableRef}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseLeave}
                        >
                            <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                                <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                                    <tr className='noscroll'>
                                        <th className='p-1 text-center align-middle' ></th>
                                        <th className='p-1 text-center align-middle'   id='tab1' >Diễn giải</th>
                                        <th className='p-1 text-center align-middle' width="100px" >Tk nợ</th>
                                        <th className='p-1 text-center align-middle'  width="100px">Tk có</th>
                                        <th className='p-1 text-center align-middle' >Số tiền</th>
                                        <th className='p-1 text-center align-middle' >Đối tượng</th>
                                        <th className='p-1 text-center align-middle' >Tên đối tượng</th>
                                        <th className='p-1 text-center align-middle' >Tk ngân hàng</th>

                                        {/* vat */}
                                        <th className='p-1 text-center align-middle bg_main_2'  >Diễn giải thuế</th>
                                        <th className='p-1 text-center align-middle bg_main_2' >Thuế GTGT %</th>
                                        <th className='p-1 text-center align-middle bg_main_2' >Tiền thuế GTGT</th>
                                        <th className='p-1 text-center align-middle bg_main_2' id='tab2'>TK Thuế GTGT</th>


                                    </tr>

                                </thead>
                                <tbody>

                                    {dataDetail1 && dataDetail1.map((row, index) => {
                                        const isLastRow = index === dataDetail1.length - 1;
                                        return <tr key={index}>
                                            <td>
                                                <Button size='small' onClick={() => deleteRow(index)}><i class="fas fa-trash-alt text-danger"></i></Button>

                                            </td>

                                            <td>
                                                <Form.Item name={"d-product_name-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-tkno-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "TK có là bắt buộc"
                                                        },
                                                    ]}
                                                    className='m-0' style={{ width: "100px" }} 
                                                // initialValue={listTKno.default}
                                                >
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={listTKno?.listAccount}
                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="Tk nợ"
                                                        filterTreeNode={filterTreeNode}
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}


                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-tkco-" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "TK có là bắt buộc"
                                                        },
                                                    ]}
                                                    className='m-0' style={{ width: "100px" }} 
                                                // initialValue={listTKco.default}
                                                >
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={listTKco?.listAccount}

                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="Tk có"
                                                        filterTreeNode={filterTreeNode}
                                                        treeDefaultExpandAll

                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}

                                                    />

                                                </Form.Item>
                                            </td>

                                            <td>
                                                <Form.Item name={"d-total-" + index} style={{ width: "100%" }} className='m-0'  initialValue={0}>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false); validateInputPrice(e) }} placeholder='Thành tiền'
                                                        onChange={(e) => { handleMoneyChange(e, "d-total", index); setTotal_(); }}
                                                       
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td style={{ width:"200px" }}>
                                                <Form.Item name={"d-suplier_id-" + index} style={{ width: "200px" }} className='m-0'>

                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={(listSuplier || []).map((value, index) => {
                                                            return {
                                                              value: value.code,
                                                              label: value.code+"-"+value.name
                                                            }
                                                          })}
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="Đối tượng"
                                                        // allowClear
                                                        // multiple
                                                        treeDefaultExpandAll
                                                        onChange={(e)=>changeSuplier(e,index)}

                                                    />

                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={"d-suplier_name-" + index} style={{ width: "100%" }} className='m-0'>

                                                          <Input/>
                                                </Form.Item>
                                            </td>
                                            <td style={{ width: "200px" }}>
                                                <Form.Item name={"d-bank_nmber-" + index} style={{ width: "200px" }} className='m-0'>

                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={(listBanks || []).map((value, index) => {
                                                            return {
                                                              value: value.id,
                                                              label: value.bank_number+"-"+value.name
                                                            }
                                                          })}

                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="TK ngân hàng"
                                                        // allowClear
                                                        // multiple
                                                        treeDefaultExpandAll
                                                       
                                                    />

                                                </Form.Item>
                                            </td>


                                            {/* vat */}
                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-note_vat-" + index} style={{ width: "100%" }} className='m-0'>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Diễn giải thuế' />
                                                </Form.Item>
                                            </td>
                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-percent_vat-" + index}
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Thuế GTGT là bắt buộc"
                                                    //     },
                                                    // ]}
                                                    style={{ width: "100%" }}
                                                    className='m-0'
                                                   
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        options={DATA_PERCENT_VAT}

                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="Thuế GTGT"
                                                        // allowClear
                                                        // multiple
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                        onChange={(e) => { handleProcessMoneyChange(e, "d-percent_vat", index);setTotal_();  }}

                                                    />

                                                </Form.Item>
                                            </td>
                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-vat_price-" + index} style={{ width: "100%" }} className='m-0'  initialValue={0}>

                                                    <Input onKeyDown={(e) => { handleKeyDown(e, false); validateInputPrice(e) }} placeholder='tiền thuế GTGT'
                                                        onChange={(e) => { handleMoneyChange(e, "d-vat_price", index); setTotal_();}}
                                                        // readOnly
                                                    />
                                                </Form.Item>
                                            </td>

                                            <td className='bg_main_2'>
                                                <Form.Item name={"d-account_vat-" + index}
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "TK GTGT là bắt buộc"
                                                    //     },
                                                    // ]}
                                                    style={{ width: "100px" }}
                                                    className='m-0'
                                                // initialValue={1331}
                                                >
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={listTKvat?.listAccount}


                                                        dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                        placeholder="TK thuế GTGT"
                                                        filterTreeNode={filterTreeNode}
                                                        treeDefaultExpandAll
                                                        onKeyDown={(e) => { handleKeyDown(e, true); validateInputPrice(e) }}



                                                    />

                                                </Form.Item>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
