import { Button, Divider, Form, Input, InputNumber, message, Radio, Select, Space, TreeSelect, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { META } from '../../../../utils/constant';
import { ListAccountantBankRequest, ListAccountingAccountRequest, ListDepartmentRequest, ListUnitNoDispatchRequest, SearchProductsWarehouseNoDispatchRequest, StoreUnitNoDispatchRequest } from '../../../../actions/indexWeb';
import { DATA_PERCENT_VAT } from '../../../../constants/DefaultData';
import {

    PlusOutlined,
} from '@ant-design/icons';
import { calculateDiscount, calculateFinalAmount, calculateTotal, calculateVat, filAcounts, formatMN1, formatNumber, handleNumberInput, parseNumberV_ } from '../../../../service';
import ListPurchase from "./ListPurchase";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const Index = ({ formRef, listDefaultAccountFil, setTotal, listSuplier,listTKco,setpurchase,listPurchaseEdit,listPurchase,dataDetail}) => {

    const [tab, setTab] = useState(1);
    const [dataDetail1, setdataDetail1] = useState([
        { department_id: "", department_name: "", amount: "",  percent: "", accounting_code:""},
    ]);
    const [listAccount, setListAccount] = useState([]);
    const [listDepartment, setListDepartment] = useState([]);


    // const [listTKno, setListTKno] = useState([]);
    // const [listTKco, setListTKco] = useState([]);
    // const [listTKvat, setListTKvat] = useState([]);

    const [meta, setMeta] = useState({ ...META });

    const newRowRef = useRef(null);
    const tableRef = useRef(null);

    const addRow = () => {
        setdataDetail1([...dataDetail1,  { department_id: "", department_name: "", amount: "", tkco: "", percent: "", accounting_code:""},])

    }
    const deleteRow = (i) => {
        if (dataDetail1.length <= 1) {
            return;
        }
        const newrow = dataDetail1.filter((value, index) => {
            return index != i;
        })
        setdataDetail1(newrow);
    }
    const handleKeyDown = (e, isLastCell = false) => {


        // console.log(e);
        if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
            e.preventDefault();
            addRow();
        }

        if (!isLastCell && (e.key === 'Enter')) {
            e.preventDefault();
            addRow();

        }
    };
    const getListDepartment = async () => {
        const response = await ListDepartmentRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                // console.log(response.data.data);
                if (response.data.data) {

                    setListDepartment(response.data.data);
                }
            }
        }


    }
    useEffect(() => {
        if(dataDetail){
         
            setdataDetail1([...dataDetail])
            dataDetail.map((value, index) => {
                for(let x in value){
                    console.log(`d-${x}-${index}`);
                    
                    if(formRef.current){
                        formRef.current.setFieldsValue({[`d-${x}-${index}`]:value[x]})
                    }
                }
            })
           

        }else{
            const ddd=[
                { department_id: "", department_name: "", amount: "",  percent: "", accounting_code:""},
            ];
            setdataDetail1(ddd)
            ddd.map((value, index) => {
                for(let x in value){
                    console.log(`d-${x}-index`);
                    
                    if(formRef.current){
                        formRef.current.setFieldsValue({[`d-${x}-${index}`]:value[x]})
                    }
                }
            })
        }
        return () => {
            
        };
    }, [dataDetail]);
  
    useEffect(() => {

        // processListAccount();

        if (newRowRef.current) {

            newRowRef.current.focus();
        }
    }, [dataDetail1 && dataDetail1.length]);

    const setTotal_ = () => {
        const total_ = {
            totalPrice: 0,
            totalVat: 0,
            totalDiscount: 0,
            total: 0,

        };
        if (formRef.current) {
            const formvalue = formRef.current.getFieldsValue();
            dataDetail1.map((value, index) => {

                total_.totalPrice += parseNumberV_(formvalue["d-total-" + index]);
                total_.totalVat += parseNumberV_(formvalue["d-vat_price-" + index]);


            })
        }

        total_.total = total_.totalPrice + total_.totalVat;

        setTotal({ ...total_ })
    }
    useEffect(() => {
        getListDepartment();
        scrollToElement(tab)
        getListAccount();

        return () => {

        };
    }, []);
    const scrollToElement = (value) => {

        setTab(value)
        const element = document.getElementById("tab" + value); // Lấy phần tử dựa trên ID
        console.log(value, element);

        if (element && tableRef.current.contains(element)) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };


    const getListAccount = async () => {
        const response = await ListAccountingAccountRequest({
            limit: meta.per_page * 100,
            page: meta.current_page, column: "code", sort: "ASC"
        });
        if (response.code == 200) {
            if (response.data) {
                console.log(response.data.data);
                if (response.data.data) {

                    setListAccount(response.data.data);
                }
            }
        }


    }

    // Hàm tìm kiếm dữ liệu
    const filterTreeNode = (inputValue, treeNode) => {
        const { title, description, code } = treeNode;
        // console.log(treeNode.props.data);

        return (
            title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
            description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
            code.toString().includes(inputValue) // Tìm kiếm theo code
        );
    };



    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (e) => {


        if (e.target.tagName != "TH") return;
        setIsDragging(true);
        setStartX(e.pageX - tableRef.current.offsetLeft);
        setScrollLeft(tableRef.current.scrollLeft);

    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - tableRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
        tableRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };
    const validateInputPrice = (e) => {
        const allowedKeys = [
            "Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", // Phím điều hướng và xoá
        ];
        const allowedCharacters = "0123456789,.";

        // Cho phép các phím điều hướng
        if (allowedKeys.includes(e.key)) return;

        // Ngăn các ký tự không hợp lệ
        if (!allowedCharacters.includes(e.key)) {
            e.preventDefault();
        }
    };
    const handleMoneyChange = (e, name, index) => {
        let inputValue = "";
        if (e.target.value) {
            inputValue = e.target.value // Loại bỏ ký tự không phải số
        } else {
            inputValue = e.toString() // Loại bỏ ký tự không phải số
        }
        const formattedValue = handleNumberInput(inputValue);

        formRef.current.setFieldsValue({
            [`${name}-${index}`]: formattedValue,
        });

    };
    const handleProcessMoneyChange = (e, name, index) => {

        if (formRef.current) {
            // switch (name) {
            //     case "":

            //         break;

            //     default:
            //         break;
            // }
            const values = formRef.current.getFieldsValue();
            const price = parseNumberV_(values["d-total-" + index]) ?? 0;
            const amount = values["d-amount-" + index] ?? 0;

            const percent_vat = values["d-percent_vat-" + index] ?? 0;


            const vat_price = price * (percent_vat / 100)


            formRef.current.setFieldsValue({ ["d-vat_price-" + index]: formatNumber(vat_price) })

        }
    };
    // useEffect(() => {
    //     //  console.log("listDefaultAccountFil",listDefaultAccountFil);

    //     processListAccount();
    //     return () => {

    //     };
    // }, [listDefaultAccountFil]);

    // const processListAccount = async () => {
    //     if (listDefaultAccountFil) {
    //         const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
    //         const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);
    //         const listTKvat_ = await filAcounts(listDefaultAccountFil, "tk-thue-gtgt", listAccount);
    //         console.log("listTKno_listTKno_listTKno_ ", listTKno_, listTKco_, listTKvat_);

    //         setListTKno(listTKno_);
    //         setListTKco(listTKco_);
    //         setListTKvat(listTKvat_);



    //     }
    // }
    useEffect(() => {
        if (formRef.current) {
            const formvalues = formRef.current.getFieldsValue();
            dataDetail1.map((row, index) => {
                formRef.current.setFieldsValue({ [`d-tkno-${index}`]: "", [`d-tkco-${index}`]: "", [`d-account_vat-${index}`]: "", })
              
                if (!formvalues[`d-tkco-${index}`]) {
                    if (listTKco.default) {
                        formRef.current.setFieldsValue({ [`d-tkco-${index}`]: listTKco.default })

                    }
                }
               
            });
        }
        return () => {

        };
    }, [listTKco]);
    const changeSuplier = (e, index) => {

        const suplier = listSuplier.filter((value, index) => {
            return value.code == e;
        })
        if (suplier[0]) {
            if (formRef.current) {
                formRef.current.setFieldsValue({ [`d-suplier_name-${index}`]: suplier[0].name })

            }
        }


    }
    return (
        <div>
            <div className={`row`}>
                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                    <div className=' bg-white p-2'>
                        <div className='d-flex'>
                        <Radio.Group
                            options={[{ value: 1, label: "Phân bổ" }, { value: 2, label: "Tập hợp chứng từ" }]}
                            onChange={(e) => {
                                const value = e.target.value;
                                scrollToElement(value)
                            }}
                            value={tab}
                            optionType="button"
                            buttonStyle="solid"
                            checked
                        />
                        <div>{listPurchase && listPurchase.map((value, index) => {
                            return <Link className="me-2">{value.code},</Link>
                        })}</div>
                        </div>
                        <div className={`row ${tab == 1 ? "" : "d-none"}`}>
                            <div className="table-responsive" style={{ height: "300px", width: "100%" }} ref={tableRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseLeave}
                            >
                                <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
                                    <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

                                        <tr className='noscroll'>
                                            <th className='p-1 text-center align-middle' ></th>
                                            <th className='p-1 text-center align-middle' id='tab1'  > Mã đơn vị</th>
                                            <th className='p-1 text-center align-middle'  >Tên đơn vị</th>
                                            <th className='p-1 text-center align-middle' >Số lượng</th>

                                            <th className='p-1 text-center align-middle'  >Tỉ lệ phân bổ</th>
                                            <th className='p-1 text-center align-middle' >TK chi phí</th>




                                        </tr>

                                    </thead>
                                    <tbody>
                                        {dataDetail1 && dataDetail1.map((row, index) => {
                                            const isLastRow = index === dataDetail1.length - 1;
                                            return <tr key={index}>
                                                <td>
                                                    <Button size='small' onClick={() => deleteRow(index)}><i className="fas fa-trash-alt text-danger"></i></Button>

                                                </td>
                                                <td>
                                                    <Form.Item name={"d-department_id-" + index}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Mã bộ phận là bắt buộc"
                                                            },
                                                        ]}
                                                        style={{ width: "150px" }}
                                                        className='m-0'
                                                        initialValue={row?.department_id}
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            options={(listDepartment || []).map((value, i) => {
                                                                return {
                                                                    value: value.id,
                                                                    label: value.code + "-" + value.name
                                                                }
                                                            })}

                                                            dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
                                                            placeholder="Mã bộ phận"
                                                            // allowClear
                                                            // multiple
                                                            treeDefaultExpandAll
                                                            onKeyDown={(e) => { handleKeyDown(e, false) }}
                                                            onChange={(e) => {
                                                                const product = listDepartment.filter((item, i) => {
                                                                    return item.id == e;

                                                                })
                                                                if (product[0]) {
                                                                    if (formRef.current) {
                                                                        formRef.current.setFieldsValue({ ["d-department_name-" + index]: product[0].name });
                                                                    }
                                                                }


                                                            }}

                                                            ref={isLastRow ? newRowRef : null}
                                                        />

                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={"d-department_name-" + index} style={{ width: "100%" }} className='m-0'
                                                        initialValue={row?.department_name}
                                                    >

                                                        <Input onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Tên bộ phận' />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item min={1} name={"d-amount-" + index} style={{ width: "100%" }} className='m-0'
                                                        initialValue={row?.amount}
                                                    >

                                                        <InputNumber onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Số lượng'  style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </td>
                                                <td>
                                                    <Form.Item name={"d-percent-" + index} style={{ width: "100%" }} className='m-0'
                                                        initialValue={row?.percent}
                                                    >

                                                        <InputNumber onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='tỉ lệ phân bổ'  style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </td>
                                                <td style={{ width: "200px" }}>
                                                    <Form.Item name={"d-accounting_code-" + index} style={{ width: "100%" }} className='m-0'
                                                        initialValue={listTKco?.default}
                                                    >

                                                        <TreeSelect
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            treeData={listTKco?.listAccount}
                                                            dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                                                            placeholder="Tk nợ"
                                                            filterTreeNode={filterTreeNode}
                                                            treeDefaultExpandAll
                                                            onKeyDown={(e) => { handleKeyDown(e, false) }}


                                                        />
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={`row ${tab == 2 ? "" : "d-none"}`}>
                            <ListPurchase setpurchase={setpurchase} listPurchaseEdit={listPurchaseEdit}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
