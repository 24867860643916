
import io from 'socket.io-client';
import { linkSocket } from "./constants/LinkApi";
import slugify from 'slugify';
import moment from 'moment';
import { Form, Input, DatePicker, Checkbox } from 'antd';
import { KEY_SYNC_ORDER_DAY } from './constants/DefaultData';
function copyToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        // Sử dụng navigator.clipboard nếu có sẵn
        navigator.clipboard.writeText(text).then(function () {
            console.log('Sao chép thành công!');
            alert('Sao chép thành công!');
        }, function (err) {
            console.error('Không thể sao chép: ', err);
            alert('Sao chép không thành công!');
        });
    } else {
        // Sử dụng document.execCommand nếu navigator.clipboard không được hỗ trợ
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999); // Cho thiết bị di động

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'Sao chép thành công!' : 'Sao chép không thành công!';
            console.log(msg);
            alert(msg);
        } catch (err) {
            console.error('Không thể sao chép: ', err);
            alert('Sao chép không thành công!');
        }

        document.body.removeChild(textArea);
    }
}
function checkScreenSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return { width, height }

}
function removeOrAndFromEndOfString(inputString) {
    // Sử dụng biểu thức chính quy để tìm và xóa "or" hoặc "and" nếu chúng ở cuối chuỗi
    const resultString = inputString.replace(/(?:\s*(?:or|and)\s*)+$/, '');

    return resultString;
}
function isDateValid(dateString) {
    const date = new Date(dateString);
    // Kiểm tra xem date là một đối tượng Date hợp lệ
    return !isNaN(date.getTime());
}

const checkItemAntjs = {

    isDateFormItem: (formItem) => {
        // Kiểm tra xem có DatePicker hoặc DatePicker với showTime không
        return formItem.props.children.type === DatePicker || formItem.props.children.type === DatePicker.RangePicker;
    },

    isStringFormItem: (formItem) => {
        // Kiểm tra xem có Input không
        return formItem.props.children.type === Input;
    },
    isBooleanFormItem: (formItem) => {
        // Kiểm tra xem có Checkbox không
        return formItem.props.children.type === Checkbox;
    }
}
const PasswordRand = {

    _pattern: /[a-zA-Z0-9_\-\+\.]/,


    _getRandomByte: function () {
        // http://caniuse.com/#feat=getrandomvalues
        if (window.crypto && window.crypto.getRandomValues) {
            var result = new Uint8Array(1);
            window.crypto.getRandomValues(result);
            return result[0];
        }
        else if (window.msCrypto && window.msCrypto.getRandomValues) {
            var result = new Uint8Array(1);
            window.msCrypto.getRandomValues(result);
            return result[0];
        }
        else {
            return Math.floor(Math.random() * 256);
        }
    },

    generate: function (length) {
        return Array.apply(null, { 'length': length })
            .map(function () {
                var result;
                while (true) {
                    result = String.fromCharCode(this._getRandomByte());
                    if (this._pattern.test(result)) {
                        return result;
                    }
                }
            }, this)
            .join('');
    }

};

function getParamsUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let [key, value] of urlParams) {
        params[key] = value;
    }
    return params;
}
function objectToUrlParams(obj) {
    return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
}
function UploadFile(fileList, folder, type = 1) {
    console.log('====================================');
    console.log("fileListfileList", fileList);
    console.log('====================================');
    try {
        const token = getCookie("acccess_token");
        const formdata = new FormData();
        // formdata.append('folder_post', "products");
        for (let i = 0; i < fileList.length; i++) {
            const element = fileList[i]?.originFileObj ? fileList[i]?.originFileObj : fileList[i];

            formdata.append("files[]", element);

        }

        formdata.append("folder", folder);
        formdata.append("type", type);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "laravel_session=m8GtjuJTpuAtVFzalaFdMLeIMECHpL0POmRMkWMN");
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        return fetch("http://127.0.0.1:8000/api/v1/uploadfile", requestOptions);


        // console.log("server res: ", res);
    } catch (err) {
        console.log("Eroor: ", err);

    }
}
function isphoneNumber(value) {
    return value.match(/\d/g).length === 10;
}
const pageToken = () => {
    let pageToken = localStorage.getItem("pageToken");
    pageToken = pageToken ? JSON.parse(pageToken) : []
    let result = [];
    for (let i = 0; i < pageToken.length; i++) {
        const element = pageToken[i];

        if (parseInt(element.active) === 1) {
            result.push(element)
        }
    }

    return result;
}
function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
function formatMoney(number, decPlaces = 0, decSep, thouSep) {
    // return Number(number).toFixed(decPlaces).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}


function createSlug(text) {
    return slugify(text, {
        lower: true,  // Chuyển đổi thành chữ thường
        strict: true, // Loại bỏ các ký tự không phù hợp
        locale: 'vi', // Sử dụng bộ quy tắc cho tiếng Việt
    });
}
const sanitizeInput = (input) => {
    // Chỉ giữ lại số và dấu chấm
    return input
        .replace(/[^0-9.]/g, '') // Loại bỏ ký tự không phải số và dấu chấm
        .replace(/(\..*?)\./g, '$1'); // Giữ lại dấu chấm đầu tiên, bỏ các dấu chấm thừa
};

const formatMN1 = (n, currency = "", fix = 0) => {
    if (n) {
        n = n.toString();
    }
    return n
        ? currency +
        Number(n)
            .toFixed(fix) // Đảm bảo số có đúng số chữ số thập phân
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Thêm dấu phẩy phân cách nhóm nghìn
        : "0";
};
const handleNumberInput = (input) => {
    // Loại bỏ ký tự không phải số, dấu chấm hoặc dấu phẩy
    let sanitizedInput = input.replace(/[^0-9.,]/g, '');

    // Tách phần nguyên và phần thập phân nếu có dấu phẩy
    const [integerPart, decimalPart] = sanitizedInput.split(',');

    // Xử lý phần nguyên: Loại bỏ dấu chấm không hợp lệ và định dạng lại
    const formattedInteger = integerPart
        ? integerPart.replace(/\./g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        : '';

    // Kết hợp phần nguyên và phần thập phân nếu có
    return decimalPart !== undefined
        ? `${formattedInteger},${decimalPart.replace(/[^0-9]/g, '')}` // Loại bỏ ký tự không hợp lệ trong phần thập phân
        : formattedInteger;
};
const parseNumber = (formattedNumber) => {
    if (!formattedNumber) return 0;

    // Đổi dấu chấm thành ký tự trống, đổi dấu phẩy thành dấu chấm
    const numericValue = formattedNumber.replace(/\./g, '').replace(/,/g, '.');

    return parseFloat(numericValue) || 0;
};
// Định dạng số thực về định dạng chuỗi (thập phân: dấu phẩy, nghìn: dấu chấm)
const formatNumber = (number) => {
    if (number === null || number === undefined) return "0";

    // Chuyển số thành chuỗi để xử lý replace
    const parseNumber = (input) => {
        if (typeof input === "string") {
            // Xử lý chuỗi: Loại bỏ dấu phân cách và chuyển dấu phẩy thành dấu chấm
            input = input.replace(/\./g, '').replace(',', '.');
        }
        return parseFloat(input); // Chuyển sang số thực
    };

    const number_ = parseNumber(number);

    if (isNaN(number_)) return "0"; // Kiểm tra nếu không phải số hợp lệ

    const format = (num) => {
        return num
            .toFixed(2) // Giữ 2 chữ số thập phân
            .replace('.', ',') // Chuyển dấu chấm thành dấu phẩy
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Thêm dấu chấm cho phân cách nghìn
    };

    return format(number_);
};
// Tính vat
const calculateVat = (amount, price, percentDiscount, vatPercent) => {
    // Xử lý định dạng để chuyển các chuỗi thành số
    const parseNumber = (input) => {
        if (!input) return 0;
        return parseFloat(
            input.replace(/\./g, '').replace(',', '.')
        ); // Chuyển dấu phẩy thành dấu chấm cho số thập phân
    };

    // Chuyển đầu vào thành số
    const qty = parseNumber(amount);
    const price_ = parseNumber(price);
    const rate = parseNumber(percentDiscount);

    // Tính tổng thành tiền
    const totalAmount = qty * price_;

    // Tính chiết khấu
    const discount = (totalAmount * rate) / 100;
    const totalAfterDiscount = (totalAmount - discount);
    const totalVat = totalAfterDiscount * (vatPercent / 100)
    // Định dạng lại kết quả thành chuỗi
    const formatNumber = (number) => {
        return number
            .toFixed(2) // Giữ 2 chữ số thập phân
            .replace('.', ',') // Chuyển dấu chấm thành dấu phẩy
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Thêm dấu chấm cho phân cách nghìn
    };

    // Định dạng lại tổng thành tiền
    return formatNumber(totalVat);
};
// Tính thành tiền
const calculateTotal = (quantity, unitPrice) => {
    const parsedQuantity = parseNumber(quantity);
    const parsedUnitPrice = parseNumber(unitPrice);

    // Tính thành tiền
    const total = parsedQuantity * parsedUnitPrice;

    // Định dạng lại tổng thành tiền
    return formatNumber(total);
};
const parseNumberV_ = (input) => {
    if (!input) return 0;
    return parseFloat(
        input.toString().replace(/\./g, '').replace(',', '.')
    ); // Chuyển dấu phẩy thành dấu chấm cho số thập phân
};
const calculateDiscount = (quantity, unitPrice, discountRate) => {
    // Xử lý định dạng để chuyển các chuỗi thành số
    const parseNumber = (input) => {
        if (!input) return 0;
        return parseFloat(
            input.replace(/\./g, '').replace(',', '.')
        ); // Chuyển dấu phẩy thành dấu chấm cho số thập phân
    };

    // Chuyển đầu vào thành số
    const qty = parseNumber(quantity);
    const price = parseNumber(unitPrice);
    const rate = parseNumber(discountRate);

    // Tính tổng thành tiền
    const totalAmount = qty * price;

    // Tính chiết khấu
    const discount = (totalAmount * rate) / 100;

    // Định dạng lại kết quả thành chuỗi
    const formatNumber = (number) => {
        return number
            .toFixed(2) // Giữ 2 chữ số thập phân
            .replace('.', ',') // Chuyển dấu chấm thành dấu phẩy
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Thêm dấu chấm cho phân cách nghìn
    };

    return {
        discount: formatNumber(discount),
        totalAfterDiscount: formatNumber(totalAmount - discount),
    };
};
const calculateFinalAmount = (amount, price, percentDiscount, additionalCosts) => {
    const parseNumber = (input) => {
        if (!input) return 0;
        return parseFloat(
            input.replace(/\./g, '').replace(',', '.')
        ); // Chuyển dấu phẩy thành dấu chấm cho số thập phân
    };
    const qty = parseNumber(amount);
    const price_ = parseNumber(price);
    const rate = parseNumber(percentDiscount);
    const additionalCosts_ = parseNumber(additionalCosts);
    // Tính thành tiền
    const totalAmount = qty * price_;

    // Tính chiết khấu
    const discount = (totalAmount * rate) / 100;
    // Tính số tiền sau giảm giá (tổng tiền - chiết khấu)
    const amountAfterDiscount = totalAmount - discount;

    // Thêm chi phí vào số tiền sau giảm giá
    const finalAmount = amountAfterDiscount + additionalCosts_;

    // Định dạng kết quả
    const formatNumber = (number) => {
        return number
            .toFixed(2) // Giữ 2 chữ số thập phân
            .replace('.', ',') // Chuyển dấu chấm thành dấu phẩy
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Thêm dấu chấm cho phân cách nghìn
    };

    return formatNumber(finalAmount);
};
function splitAndRoundMultiple(number, ratios, decimals = 3) {
    const totalRatio = ratios.reduce((sum, r) => sum + r, 0); // Tổng các tỷ lệ
    let result = [];
    let accumulated = 0;

    // Duyệt qua từng tỷ lệ
    for (let i = 0; i < ratios.length; i++) {
        if (i === ratios.length - 1) {
            // Phần cuối cùng bù sai lệch
            result.push(Number((number - accumulated).toFixed(decimals)));
        } else {
            // Tính từng phần
            const part = Number((number * (ratios[i] / totalRatio)).toFixed(decimals));
            accumulated += part; // Cộng dồn giá trị để tính sai lệch
            result.push(part);
        }
    }

    return result;
}
function formatMN2(n, currency = "") {
    return n ? currency + Number(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : 0;
}

function formatMN3(n, currency = "") {
    return n ? n.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    }) : 0
}


const validateNUmber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
const replaceThous = (n) => {
    if (!n) {
        return;
    }
    n = n.toString()
    // return n
    // return n.replace(",", "").replace(",", "").replace(",", "")
    return n.replace(/[^\w\s]/gi, "")
}


function formatDate(timetsamp) {
    var date = new Date(timetsamp);
    // return date.toLocaleDateString();
    var d = date.getDate().length == 1 ? "0" + date.getDate().length : date.getDate();
    var m = date.getMonth().length == 1 ? "0" + date.getMonth().length : date.getMonth();
    var h = date.getHours().length == 1 ? "0" + date.getHours().length : date.getHours();
    var i = date.getMinutes().length == 1 ? "0" + date.getMinutes().length : date.getMinutes();
    var s = date.getSeconds().length == 1 ? "0" + date.getSeconds().length : date.getSeconds();
    return date.getFullYear() +
        "-" + m +
        "-" + d +
        " " + h +
        ":" + i +
        ":" + s
}

function formatDateVN(timetsamp, time = false) {
    var date = new Date(timetsamp);
    if (time) {
        return date.getDate() +
            "/" + (date.getMonth() + 1) +
            "/" + date.getFullYear() +
            " " + date.getHours() +
            ":" + date.getMinutes() +
            ":" + date.getSeconds()
    }
    else {
        return date.getDate() +
            "/" + (date.getMonth() + 1) +
            "/" + date.getFullYear()

    }


}
function CalDiscount(price, num, discount) {
    return num * (price * discount) / 100;
}
function formatDate_fm(timetsamp) {
    var date = new Date(timetsamp);
    return date.getDate() +
        "/" + (date.getMonth() + 1) +
        " " + date.getHours() +
        ":" + date.getMinutes() +
        ":" + date.getSeconds()


}
const money = new Intl.NumberFormat('vi-VN',
    { style: 'currency', currency: 'VND' });

const percent = new Intl.NumberFormat('de-CH',
    { style: 'percent', maximumFractionDigits: 1, signDisplay: "always" });
function CutString(item, num, middle = true) {

    if (!item) {
        return "";
    }
    item = item.toString();
    if (item.length <= num * 2) {
        return item;
    }
    else {
        if (middle) {
            return item.slice(0, num) + "..." + item.slice(-(num))
        }
        else {
            return item.slice(0, num) + "...";
        }
    }

}
function SetProductsData(key, value) {
    let productsData = localStorage.getItem("productsData");

    if (productsData) {
        productsData = JSON.parse(productsData);
        if (!productsData[key] || !productsData[key].data) {
            productsData[key] = value;
            localStorage.setItem("productsData", JSON.stringify(productsData));
        }
    } else {
        // localStorage.setItem("productsData", JSON.stringify({ [item]: nextProps.Web[item] }));
    }

}

function connectSocket() {
    // const socket = io(linkSocket);

    // socket.on('connect', function ()
    // {
    //     console.log('Connected to Laravel WebSockets server');
    // });

    // socket.on('disconnect', function ()
    // {
    //     console.log('Disconnected from Laravel WebSockets server');
    // });
    // socket.on("message", function (event)
    // {
    //     console.log('mes' + event.data);
    // });

    // socket.on("send", function (event)
    // {
    //     console.log('mes' + event.data);
    // });
    // const socket = new WebSocket(linkSocket);

    // socket.addEventListener('open', (event) =>
    // {
    //     console.log('Đã kết nối tới WebSocket server trên Laravel');
    //     return {
    //         send: (data) =>
    //         {
    //             // JSON.stringify({
    //             //     'type': 'chat',
    //             //     'user_id': 10,
    //             //     'user_name': 'dat',
    //             //     'chat_msg': "heelo"
    //             // })
    //             if (data) {
    //                 socket.send(data)
    //             }

    //         },
    //         message: () =>
    //         {
    //             socket.addEventListener('message', (event) =>
    //             {
    //                 console.log('Nhận được tin nhắn mới: ' + event.data);
    //             });
    //         }
    //     }

    // });


}
function CheckUnionArr(arr1, arr2, col = "") {
    if (arr1 && arr2) {
        for (let i = 0; i < arr1.length; i++) {
            const element1 = arr1[i];
            for (let j = 0; j < arr2.length; j++) {
                const element2 = arr2[j];
                if (!col) {
                    if (element1 === element2) {

                        // Return if common element found
                        return element1;
                    }
                } else {

                    if (element1 === element2[col]) {

                        // Return if common element found
                        return element1;
                    }
                }

            }
        }
    }

    return "";
}
// Chuyển đổi từ mm sang px
function mmToPx(mm) {
    const pxPerInch = 96; // Giả sử màn hình có độ phân giải 96 DPI
    const mmPerInch = 25.4;
    return (mm / mmPerInch) * pxPerInch;
}
// Chuyển đổi từ px sang mm
function pxToMm(px) {
    const pxPerInch = 96; // Giả sử màn hình có độ phân giải 96 DPI
    const mmPerInch = 25.4;
    return (px / pxPerInch) * mmPerInch;
}
function convertToBoolean(value) {
    return parseInt(value) === 1 || value == true;
}

// Hàm để tính khoảng thời gian dựa trên key
function getDateRange(key) {
    const today = moment();
    let fromDate;
    let toDate = today.clone().endOf('day'); // Kết thúc ngày hôm nay

    switch (key) {
        case KEY_SYNC_ORDER_DAY.TODAY:
            fromDate = today.clone().startOf('day');
            break;
        case KEY_SYNC_ORDER_DAY.THIS_WEEK:
            fromDate = today.clone().startOf('week');
            break;
        case KEY_SYNC_ORDER_DAY.THIS_MONTH:
            fromDate = today.clone().startOf('month');
            break;
        case KEY_SYNC_ORDER_DAY.LAST_3_MONTH:
            fromDate = today.clone().subtract(3, 'months').startOf('month');
            break;
        case KEY_SYNC_ORDER_DAY.LAST_6_MONTH:
            fromDate = today.clone().subtract(6, 'months').startOf('month');
            break;
        case KEY_SYNC_ORDER_DAY.THIS_YEAR:
            fromDate = today.clone().startOf('year');
            break;
        default:
            throw new Error('Invalid key');
    }

    return { fromDate: fromDate.toISOString(), toDate: toDate.toISOString() };
}
function isValidURL(string) {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
}

const filAcounts = (listDefaultAccountFil, key, listAccount) => {
    if (listDefaultAccountFil) {

        // console.log("listAccount ",listAccount);

        let accounts = listDefaultAccountFil.accounts;

        if (accounts) {
            accounts = Object.values(accounts);
            const accountFil = accounts.filter((value, index) => {
                return value.code_account == key;
            })


            let dataFil = listAccount;
            if (accountFil[0]) {
                if (accountFil[0].accounts) {
                    if (accountFil[0].accounts == -1) {
                        dataFil = listAccount;
                    } else {
                        accounts = accountFil[0].accounts.split(";").map(account => parseInt(account.trim().substring(0, 3)));
                        dataFil = listAccount.filter((value, index) => {
                            return accounts.includes(parseInt(value.code.toString().substring(0, 3)));
                        })
                    }
                    return { listAccount: dataFil, default: accountFil[0]?.default_account ? parseInt(accountFil[0]?.default_account) : "" };
                }

            }
            return { listAccount: listAccount, default: 0 };
        }

        return { listAccount: listAccount, default: 0 };
    }
    return { listAccount: listAccount, default: 0 };
}
function convertDayOfWeekToVietnamese(dayOfWeek) {
    const daysOfWeek = [
        "Chủ nhật", // 0
        "Thứ hai",  // 1
        "Thứ ba",   // 2
        "Thứ tư",   // 3
        "Thứ năm",  // 4
        "Thứ sáu",  // 5
        "Thứ bảy"   // 6
    ];

    // Vì trong nhiều hệ thống, ngày bắt đầu từ Chủ nhật là 0, nhưng nếu ngày của bạn bắt đầu từ 1, ta sẽ điều chỉnh lại.
    return daysOfWeek[dayOfWeek % 7]; // %7 để xử lý trường hợp giá trị ngoài phạm vi 1-7 (nếu có)
}
const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      code.toString().includes(inputValue) // Tìm kiếm theo code
    );
  };
export {
    validateNUmber,
    pageToken,
    formatMoney,
    replaceThous,
    formatMN3,
    formatMN2,
    formatMN1,
    formatDate,
    formatDate_fm,
    formatDateVN,
    setCookie,
    getCookie,
    eraseCookie,
    isphoneNumber,
    money,
    percent,
    PasswordRand,
    CutString,
    SetProductsData,
    connectSocket,
    UploadFile,
    CheckUnionArr,
    CalDiscount,
    getParamsUrl,
    createSlug,
    checkScreenSize,
    isDateValid,
    removeOrAndFromEndOfString,
    objectToUrlParams,
    copyToClipboard,
    mmToPx,
    pxToMm,
    convertToBoolean,
    getDateRange,
    isValidURL,
    filAcounts,
    splitAndRoundMultiple,
    convertDayOfWeekToVietnamese,
    sanitizeInput,
    handleNumberInput,
    calculateTotal,
    calculateDiscount,
    calculateFinalAmount,
    calculateVat,
    parseNumberV_,
    formatNumber,
    filterTreeNode
}