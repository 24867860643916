import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, message, Modal, Popover, Select, Table, Tooltip, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListTimeKeepingRequest, CreateDataTimeKeepingRequest, SetTimeKeepingRequest, ListSalariesRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_TYPE_ACCOUNTING } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';
import { convertDayOfWeekToVietnamese, formatN, formatNumber, formatNumberumber } from '../../../../service';

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [listTimeKeeping, setListTimeKeeping] = useState([]);
  const [listdays, setListdays] = useState([]);
  const [listTotal, setListTotal] = useState([]);
  const [listTotalAll, setListTotalAll] = useState([]);
  const [listAllowance, setlistAllowance] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [record, setRecord] = useState(null);
  const [Listdate, setdate] = useState([]);
  const [Listday, setday] = useState([]);
  const formSearchRef = useRef(null);
  const tableRef = useRef(null);

  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [currentYear, setCurrentYear] = useState(moment().startOf('year'));

  const history = useHistory();

  const getListTimeKeeping = async (values) => {
    const response = await ListSalariesRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    }, { data: { ...values } });
    if (response.code == 200) {
      if (response.data) {
        setListTimeKeeping(response.data);
        // setListdays(response.days);
        setListTotal(response.total)
        // setListTotalAll(response.totalsAll)
        setlistAllowance(response.listAllowances)
      }
    }


  }
  useEffect(() => {
    const filteredvalues = { date: currentMonth.format('YYYY-MM-DD') }
    getListTimeKeeping(filteredvalues);
    filteredvalues.date = moment(filteredvalues.date).format('MM-YYYY');
    const searchParams = new URLSearchParams(filteredvalues).toString();
    history.push(`${LINK.WEB_ACCOUNTANT_SALARY}?${searchParams}`);
    return () => {

    };
  }, []);

  useEffect(() => {
    renderDayOfMonth();
    return () => {

    };
  }, [listdays]);
  const onSearch = async (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== undefined)
    );
    filteredValues.date = filteredValues.date.format('YYYY-MM-DD')
    getListTimeKeeping(filteredValues);
    const month = moment(filteredValues.date).startOf("month");
    const year = moment(filteredValues.date).startOf("year");
    setCurrentMonth(month);
    setCurrentYear(year);
    filteredValues.date = moment(filteredValues.date).format('MM-YYYY');
    const searchParams = new URLSearchParams(filteredValues).toString();
    history.push(`${LINK.WEB_ACCOUNTANT_SALARY}?${searchParams}`);
  }
  const confirmCreateData = (e) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: 'Tạo dữ liệu ' + e + " Tháng mới nhất?",
      okText: 'Đồng ý',
      cancelText: 'Bỏ qua',
      onOk: () => {
        createTimekeeping(e);

      }
    });
  };
  const createTimekeeping = async (e) => {
    setIsloading(true);
    console.log("eeeee ", e);

    const response = await CreateDataTimeKeepingRequest({ number: e });
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const renderDayOfMonth = () => {
    const date = [];
    const day = [];
    console.log("listTimeKeepinglistTimeKeepinglistTimeKeeping", listTimeKeeping);
    if (listdays) {
      listdays.map((value, index) => {
        const formattedDate = moment(value.date).format('DD');
        date.push(formattedDate);
        day.push(convertDayOfWeekToVietnamese(value.day_of_week_number));
      })
    }

    setdate(date)
    setday(day)
    // return {date,day}
  }
  const confirmSetTime = (e, item, name) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: e.target.checked ? 'Chấm công cho nhân viên ' + name + ' ngày ' + moment(item.date).format("DD/MM/YYYY") + "?" : 'Bỏ chấm công cho nhân viên ' + name + ' ngày ' + moment(item.date).format("DD/MM/YYYY") + "?",
      okText: 'Đồng ý',
      cancelText: 'Bỏ qua',
      onOk: () => {
        setTimeKeeping(e, item);

      }
    });
  };
  const setTimeKeeping = async (e, item) => {
    if (item?.id) {
      setIsloading(true);
      console.log("eeeee ", e);

      const response = await SetTimeKeepingRequest({ id: item?.id, checked: e.target.checked, date: item.date });
      setIsloading(false);
      if (response.code == 200) {
        if (response.message) {
          message.success(response.message);
        } else {
          message.success("Cập nhật thành công");
        }
      } else {
        if (response.message) {
          if (typeof response.message == "string") {
            message.error(response.message);
          } else {
            (Object.values(response.message) || []).map((value, index) => {
              message.error(value);
            })
          }

        } else {
          message.warning("Cập nhật không thành công");
        }
      }
    }

  }
  const resetFormSearch = () => {
    formSearchRef.current.resetFields();
    formSearchRef.current.submit();
  }
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const handleMouseDown = (e) => {


    if (e.target.tagName != "TH") return;
    setIsDragging(true);
    setStartX(e.pageX - tableRef.current.offsetLeft);
    setScrollLeft(tableRef.current.scrollLeft);

  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };
  const renderTimekeeping = () => {
    const result = [];
    listTimeKeeping.map((depart, iDepart) => {
      // console.log(depart?.allowances);

      result.push(<tr className='fw-bold bg_main_2 text-white'>
        {/* phòng ban */}
        <td colSpan={5} className='bg_main_2' style={{ position: "sticky", zIndex: 100, left: 0 }} >{depart.department_name}</td>
        {/* tổng phụ cấp theo phòng */}
        {listAllowance && listAllowance.map((value, index) => {
          if (depart?.allowances[value.id]) {
            return <th className='p-1 text-right align-middle'>{formatNumber(depart?.allowances[value.id])}</th>
          } else {
            return <th className='p-1 text-right align-middle'>{0}</th>
          }

        })}

        {/* tổng lương theo phòng */}
        <td className='text-right '>{formatNumber(depart?.total_salary)}</td>
        {/* tổng ngày theo phòng */}
        <td className='text-center '>{formatNumber(depart?.days)}</td>
        {/* tổng lương ngày công theo phòng */}
        <td className='text-right '>{formatNumber(depart?.totalMoneyOfdays)}</td>
        {/* tổng  ngày chủ nhật theo phòng */}
        <td className='text-center '>{formatNumber(depart?.sundays)}</td>
        {/* tổng  lương ngày chủ nhật theo phòng */}
        <td className='text-right '>{formatNumber(depart?.totalSalarysundays)}</td>
        {/* tổng  h tăng ca theo phòng */}
        <td className='text-center '>{0}</td>
        {/* tổng  tiền tăng ca theo phòng */}
        <td className='text-right '>{formatNumber(0)}</td>
        {/* tổng  thu nhập theo phòng */}
        <td className='text-right '> {formatNumber(depart?.totalIncome)}</td>
        {/* tổng  lương bảo hiểm theo phòng */}
        <td className='text-right '>{formatNumber(depart?.totalSalaryInsurance)}</td>


        {/* bhxh theo phòng */}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalSocialInsuranceCompany)}
        </td>
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalHealthInsuranceCompany)}
        </td>
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalAccidentInsuranceCompany)}
        </td>
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalInsuranceCompany)}

        </td>

        {/* bhxh nhan vien theo phòng*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalSocialInsuranceEmployee)}
        </td>
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalHealthInsuranceEmployee)}
        </td>
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalAccidentInsuranceEmployee)}
        </td>
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalInsuranceEmployee)}
        </td>
        {/* Thu nhập chịu thuế*/}
        <td className='text-right '> {formatNumber(depart?.taxableIncome)}</td>
        {/* Giảm trừ gia cảnh*/}
        <td className='text-right '> {formatNumber(depart?.familyDeduction)}</td>
        {/* Số người PT*/}
        <td className='text-center ' style={{ zIndex: 0, }}>
          {(depart?.dependentDeduction)}
        </td>
        {/*tiền Số người PT*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.moneyDependentDeduction)}
        </td>
        {/*tỏng giảm trừ gia cảnh*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalFamilyDeduction)}
        </td>
        {/*tỏng giảm trừ gia cảnh*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.totalInsuranceEmployee)}
        </td>
        {/*Từ thiện*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.charity)}
        </td>
        {/*hưu trí tự nguyện*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.pensionFund)}
        </td>
        {/*Tiền tính thuế*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.taxMoneyIncome)}
        </td>
        {/*bậc tính thuế*/}
        <td className='text-right ' style={{ zIndex: 0, }}>

        </td>
        {/* thuế TNCN*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.personalTax)}
        </td>
        {/* Lương thực lãnh trừ TNCN và thuế*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.salaryReal)}
        </td>
        {/* tiền tạm ứng*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.salaryAdvance)}
        </td>
        {/* Lương thực lãnh còn lại*/}
        <td className='text-right ' style={{ zIndex: 0, }}>
          {formatNumber(depart?.remainingSalary)}
        </td>
      </tr>)
      if (depart.employees) {
        for (const x in depart.employees) {
          const value = depart.employees[x];
          result.push(<tr key={iDepart + x}>
            {/* thông tin chung */}
            <td className='text-center bg-white' style={{ position: "sticky", zIndex: 100, left: 0 }}>{iDepart + 1}</td>
            <td className='bg-white' style={{ position: "sticky", zIndex: 100, left: "20px" }}>{value.employee_code}</td>
            <td className='bg-white' style={{ position: "sticky", zIndex: 100, left: "120px" }}>{value.employee_name}</td>
            <td className='bg-white' style={{ position: "sticky", zIndex: 100, left: "318px" }}>{value.position_name}</td>
            <td className='bg-white text-right' style={{ position: "sticky", zIndex: 100, left: "318px" }}>{formatNumber(value.salary)}</td>
            {/* Phụ cấp */}

            {listAllowance && listAllowance.map((vAl, iAl) => {
              if (value?.allowances?.[vAl.id]) {
                return <th className='p-1 text-right align-middle'>{formatNumber(value?.allowances[vAl.id])}</th>
              } else {
                return <th className='p-1 text-right align-middle'>{0}</th>
              }

            })}
            {/* tổng thu nhập */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.total_salary)}
            </td>
            {/* tổng Ngày */}
            <td className='text-center ' style={{ zIndex: 0, }}>
              {value?.days}
            </td>
            {/* tổng lương theo ngày */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalMoneyOfdays)}
            </td>
            {/* tổng ngày chủ nhật */}
            <td className='text-center ' style={{ zIndex: 0, }}>
              {(value?.sundays)}
            </td>
            {/* tổng lương ngày chủ nhật */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalSalarysundays)}
            </td>
            {/* tổng h tăng ca */}
            <td className='text-center ' style={{ zIndex: 0, }}>
              0
            </td>
            {/* tổng lương  tăng ca */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(0)}
            </td>
            {/* tổng thu nhập */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalIncome)}
            </td>
            {/* tổng lương bảo hiểm */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalSalaryInsurance)}
            </td>
            {/* bhxh */}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalSocialInsuranceCompany)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalHealthInsuranceCompany)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalAccidentInsuranceCompany)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalInsuranceCompany)}

            </td>
            {/* bhxh nhan vien*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalSocialInsuranceEmployee)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalHealthInsuranceEmployee)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalAccidentInsuranceEmployee)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalInsuranceEmployee)}

            </td>
            {/* Thu nhập chịu thuế*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.taxableIncome)}
            </td>
            {/* Giảm trừ gia cảnh*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.familyDeduction)}
            </td>
            {/* Số người PT*/}
            <td className='text-center ' style={{ zIndex: 0, }}>
              {(value?.dependentDeduction)}
            </td>
            {/*tiền Số người PT*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.moneyDependentDeduction)}
            </td>
            {/*tỏng giảm trừ gia cảnh*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalFamilyDeduction)}
            </td>
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.totalInsuranceEmployee)}

            </td>

            {/*Từ thiện*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.charity)}
            </td>
            {/*hưu trí tự nguyện*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.pensionFund)}
            </td>
            {/*Tiền tính thuế*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.taxMoneyIncome)}
            </td>
            {/*bậc tính thuế*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.levelTax)}
            </td>
            {/* thuế TNCN*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.personalTax)}
            </td>
            {/* Lương thực lãnh trừ TNCN và thuế*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.salaryReal)}
            </td>
            {/* tiền tạm ứng*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.salaryAdvance)}
            </td>
            {/* Lương thực lãnh còn lại*/}
            <td className='text-right ' style={{ zIndex: 0, }}>
              {formatNumber(value?.remainingSalary)}
            </td>
          </tr>)
        }
      }


    })

    return result;
  }
  return (
    <Card title={<div style={{ display: "flex", alignItems: "center" }}>
      <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
      <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
      <span style={{ marginLeft: "10px", color: Colors.pop2[3] }} >Bảng lương</span>
      {/* <span style={{ marginLeft: "10px", color: tab == 2 ? Colors.pop2[3] : "#000", cursor: "pointer" }} onClick={() => setTab(2)}>Hóa đơn</span> */}
    </div>} bordered={false}
      style={{ width: "100%", margin: "0 auto" }}
      bodyStyle={{
        padding: "10px",
        // maxHeight: "90vh",
        // overflowY: "scroll"

      }}
      headStyle={{
        backgroundColor: Colors.colorgrey,
        display: "flex",
        minHeight: "30px",
        borderRadius: "3px"
      }}
    >
      <div className='w-100 row  bg-white'>

        <div className='col-lg-12 col-md-12 col-sm-12 '>
          <Form name="formsearch" onFinish={onSearch} ref={formSearchRef}
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 20 }}
          >
            <Form.Item>
              <Input.Group compact>
                <Form.Item name="code" className='m-0'>
                  <Input placeholder="Mã" />
                </Form.Item>
                <Form.Item name="name" className='m-0'>
                  <Input placeholder="Tên" />
                </Form.Item>
                <Form.Item name="date" className='m-0' initialValue={currentMonth}>
                  <DatePicker picker="month" format={"MM/YYYY"} />
                </Form.Item>
                <Form.Item name="description" className='m-0'>
                  <Input placeholder="Mô tả" />
                </Form.Item>
                <Form.Item className='m-0'>
                  <div className="flex_">
                    <Button type="default" className='bg-warning' onClick={() => { resetFormSearch() }}>reset</Button>
                    <Button type="primary" loading={isloading} htmlType="submit"  ><SearchOutlined /></Button>
                    <Popover placement="bottom" title={"Tạo DL chấm công"} content={
                      <div>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(1)}>1 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(2)}>2 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(3)}>3 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(6)}>6 tháng</p>
                        <p style={{ cursor: "pointer" }} onClick={() => confirmCreateData(2)}>12 tháng</p>
                      </div>
                    } trigger="click">
                      <Button>Khỏi tạo dữ liệu</Button>
                    </Popover>
                  </div>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 '>
          <div className="table-responsive" style={{ height: "750px", cursor: "move" }} ref={tableRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <table className="table table-ledger table-sm table-hover" style={{ width: "max-content", minWidth: "100%" }} >
              <thead style={{ position: "sticky", top: 0, zIndex: 1000 }}>
                <tr>

                  <th className='p-1 text-center align-middle bg_main' width="20px" rowSpan={3} style={{ position: "sticky", zIndex: 100, left: 0 }}>
                    STT
                  </th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="100px" style={{ position: "sticky", zIndex: 100, left: "20px" }}> Mã NV</th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="250px" style={{ position: "sticky", zIndex: 100, left: "120px" }}>Tên NV</th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="" style={{ position: "sticky", zIndex: 100, left: "120px" }}>Chức vụ</th>
                  <th className='p-1 text-center align-middle bg_main' rowSpan={3} width="" style={{ position: "sticky", zIndex: 100, left: "120px" }}>Lương CB</th>
                  <th className='p-1 text-center align-middle' colSpan={listAllowance.length}>Phụ cấp/Trợ cấp</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Lương CB+phụ cấp</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Ngày công</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Tiền lương theo ngày công</th>

                  <th className='p-1 text-center align-middle' rowSpan={1} colSpan={4}>Tăng ca</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Tổng thu nhập</th>
                  <th className='p-1 text-center align-middle' rowSpan={3}>Lương đóng BHXH</th>

                  <th className='p-1 text-center align-middle' rowSpan={2} colSpan={4}>Các khoản trích tính vào chi phí DN</th>
                  <th className='p-1 text-center align-middle' rowSpan={2} colSpan={4}>Trích Bảo Hiểm Nhân Viên</th>
                  {/* <th className='p-1 text-center align-middle' rowSpan={3} >Thực lãnh</th> */}
                  <th className='p-1 text-center align-middle' rowSpan={3} >Thu nhập chịu thuế</th>
                  <th className='p-1 text-center align-middle' colSpan={7} >Các khoản được giảm trừ</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Thu nhập tính thuế</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Bậc tính thuế</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Thuế TNCN phải nộp</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Lương thực lãnh</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Tạm ứng lương</th>
                  <th className='p-1 text-center align-middle' rowSpan={3} >Còn lãnh</th>


                </tr>
                <tr>
                  <th className='p-1 text-center align-middle' colSpan={listAllowance.filter(value => value.is_social_insurance === 1).length}>Phụ cấp đóng BHXH</th>
                  <th className='p-1 text-center align-middle' colSpan={listAllowance.filter(value => value.is_social_insurance === 2).length}>Phụ cấp không đóng BHXH</th>
                  <th colSpan={2} className='text-center'>Chủ nhật</th>
                  <th colSpan={2} className='text-center'>Ngoài giờ</th>
                  <th className='text-center align-middle' rowSpan={2}>Giảm trừ Gia cảnh</th>
                  <th className='text-center align-middle' colSpan={2}>Giảm trừ NPT</th>
                  <th className='text-center align-middle' rowSpan={2} >Tổng giảm trừ gia cảnh</th>
                  <th className='text-center align-middle' rowSpan={2}  >Bảo hiểm nhân viên đóng</th>
                  <th className='text-center align-middle' rowSpan={2} >Từ thiện, nhân đạo, khuyến học</th>
                  <th className='text-center align-middle' rowSpan={2} >Quỹ hưu trí tự nguyện</th>
                </tr>
                <tr>
                  {listAllowance && listAllowance.map((value, index) => {
                    return <th className='p-1 text-center align-middle'>{value.name}</th>
                  })}
                  <th className='text-center align-middle'>Số ngày</th>
                  <th className='text-center align-middle'>Số tiền</th>
                  <th className='text-center align-middle'>Số giờ</th>
                  <th className='text-center align-middle'>Số tiền</th>

                  {/* BHXH doanh nghiệp */}
                  <th className='text-center align-middle'>BHXH (17.5%)</th>
                  <th className='text-center align-middle'>BHYT (3%)</th>
                  <th className='text-center align-middle'>BHTN (1%)</th>
                  <th className='text-center align-middle'>Tổng cộng</th>

                  {/* BHXH Nhân viên */}
                  <th className='text-center align-middle'>BHXH (8%)</th>
                  <th className='text-center align-middle'>BHYT (1.5%)</th>
                  <th className='text-center align-middle'>BHTN (1%)</th>
                  <th className='text-center align-middle'>Tổng cộng</th>


                  {/* BHXH Nhân viên */}
                  <th className='text-center align-middle'>Số NPT</th>
                  <th className='text-center align-middle'>Số tiền</th>



                </tr>

                {/* <tr>

                  {Listdate && Listdate.map((value, index) => {
                    return <th key={index} className='text-center' style={{ zIndex: 0, }}>
                      {value}
                    </th>
                  })}
                </tr>

                <tr>

                  {Listday && Listday.map((value, index) => {
                    return <th key={index} className={`text-center ${value === "Thứ bảy" ? "bg-info" : value === "Chủ nhật" ? "bg-danger" : ""}`} style={{ zIndex: 0, }}>
                      {value}
                    </th>
                  })}

                </tr> */}



              </thead>
              <tbody>
                {renderTimekeeping()}

                {/* tổng */}
                <tr className='fw-bold bg_main_2 text-white'>
                  {/* phòng ban */}
                  <td colSpan={5} className='bg_main_2' style={{ position: "sticky", zIndex: 100, left: 0 }} >Tổng</td>
                  {/* tổng phụ cấp theo phòng */}
                  {listAllowance && listAllowance.map((value, index) => {
                    if (listTotal?.allowances[value.id]) {
                      return <th className='p-1 text-right align-middle'>{formatNumber(listTotal?.allowances[value.id])}</th>
                    } else {
                      return <th className='p-1 text-right align-middle'>{0}</th>
                    }

                  })}

                  {/* tổng lương theo phòng */}
                  <td className='text-right '>{formatNumber(listTotal?.total_salary)}</td>
                  {/* tổng ngày theo phòng */}
                  <td className='text-center '>{formatNumber(listTotal?.days)}</td>
                  {/* tổng lương ngày công theo phòng */}
                  <td className='text-right '>{formatNumber(listTotal?.totalMoneyOfdays)}</td>
                  {/* tổng  ngày chủ nhật theo phòng */}
                  <td className='text-center '>{formatNumber(listTotal?.sundays)}</td>
                  {/* tổng  lương ngày chủ nhật theo phòng */}
                  <td className='text-right '>{formatNumber(listTotal?.totalSalarysundays)}</td>
                  {/* tổng  h tăng ca theo phòng */}
                  <td className='text-center '>{0}</td>
                  {/* tổng  tiền tăng ca theo phòng */}
                  <td className='text-right '>{formatNumber(0)}</td>
                  {/* tổng  thu nhập theo phòng */}
                  <td className='text-right '> {formatNumber(listTotal?.totalIncome)}</td>
                  {/* tổng  lương bảo hiểm theo phòng */}
                  <td className='text-right '>{formatNumber(listTotal?.totalSalaryInsurance)}</td>


                  {/* bhxh theo phòng */}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalSocialInsuranceCompany)}
                  </td>
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalHealthInsuranceCompany)}
                  </td>
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalAccidentInsuranceCompany)}
                  </td>
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalInsuranceCompany)}

                  </td>

                  {/* bhxh nhan vien theo phòng*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalSocialInsuranceEmployee)}
                  </td>
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalHealthInsuranceEmployee)}
                  </td>
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalAccidentInsuranceEmployee)}
                  </td>
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalInsuranceEmployee)}
                  </td>
                  {/* Thu nhập chịu thuế*/}
                  <td className='text-right '> {formatNumber(listTotal?.taxableIncome)}</td>
                  {/* Giảm trừ gia cảnh*/}
                  <td className='text-right '> {formatNumber(listTotal?.familyDeduction)}</td>
                  {/* Số người PT*/}
                  <td className='text-center ' style={{ zIndex: 0, }}>
                    {(listTotal?.dependentDeduction)}
                  </td>
                  {/*tiền Số người PT*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.moneyDependentDeduction)}
                  </td>
                  {/*tỏng giảm trừ gia cảnh*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalFamilyDeduction)}
                  </td>
                  {/*tỏng giảm trừ gia cảnh*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.totalInsuranceEmployee)}
                  </td>
                  {/*Từ thiện*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.charity)}
                  </td>
                  {/*hưu trí tự nguyện*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.pensionFund)}
                  </td>
                  {/*Tiền tính thuế*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.taxMoneyIncome)}
                  </td>
                  {/*bậc tính thuế*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>

                  </td>
                  {/* thuế TNCN*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.personalTax)}
                  </td>
                  {/* Lương thực lãnh trừ TNCN và thuế*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.salaryReal)}
                  </td>
                  {/* tiền tạm ứng*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.salaryAdvance)}
                  </td>
                  {/* Lương thực lãnh còn lại*/}
                  <td className='text-right ' style={{ zIndex: 0, }}>
                    {formatNumber(listTotal?.remainingSalary)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </Card>
  )
}

export default Index
