import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, message, Radio, Select, Table, TreeSelect } from 'antd';
import * as LINK from "../../../../constants/Links";

import "./../style.css"
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { META } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { ListAccountantBankRequest, ListAccountantInvoiceTemplateRequest, ListAccountingAccountRequest, ListDefaultAccountRequest, ListEmployeesRequest, ListUsersNodispatchRequest, SearchCustomersNoDispatchRequest, SearchSupplierNoDispatchRequest, StoreAccountantPurchaseRequest, StoreAccountingAccountRequest } from '../../../../actions/indexWeb';
import { DATA_GROUP_ACCOUNT, DATA_REASON_PAYMENT, DATA_REASON_RECEIPT, DATA_STOCK_EXPORT, DATA_STOCK_IMPORT, DATA_TYPE_ACCOUNTING, DATA_TYPE_PURCHASE_RECEIPT, DATA_TYPE_PURCHASE_RECEIPT_ADD_BILL, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT, DATA_TYPE_PURCHASE_RECEIPT_PAYMENT_ACCOUNT, DATA_TYPE_SELL, DATA_TYPE_SELL_ACCOUNT } from '../../../../constants/DefaultData';
import {
  FolderViewOutlined, AppstoreOutlined,
  DeleteOutlined, PlusCircleOutlined,
  EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import * as Colors from "../../../../constants/Colors";
import Planning from "./planning";
import moment from 'moment';
import { createSlug, formatMN1, formatNumber, parseNumberV_ } from '../../../../service';

const Index = () => {

  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const params = useParams();
  const { typeV } = params;
  // console.log(typeV);

  const [meta, setMeta] = useState({ ...META });

  const [listDefaultAccount, setListDefaultAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [tab, setTab] = useState(1);
  const [typeObject, setTypeObject] = useState(1);
  const [record, setRecord] = useState(null);

  const [total_, setTotal_] = useState({
    totalPrice: 0,
    totalVat: 0,
    totalDiscount: 0,
    total: 0,

  });

  const [AccountPayment, setAccountPayment] = useState(1);

  const formRef = useRef(null);
  const dataTab1 = [{ value: 1, label: "Chứng từ ghi nợ" }, { value: 2, label: "Phiếu xuất" }, { value: 3, label: "Hóa đơn" }];
  const dataTab2 = [{ value: 1, label: "Phiếu thu" }, { value: 2, label: "Phiếu xuất" }, { value: 3, label: "Hóa đơn" }];

  const defaultDate = moment().startOf('day');

  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount]);
  const removePrefixThu = (str, text) => {
    if (str.startsWith(text)) {
      return str.slice(3).trim();
    }
    return str;
  };
  const filterAccount = () => {

    if (listDefaultAccount.length > 0) {
      //   // DATA_TYPE_PURCHASE_RECEIPT
      //   let filKey = "";

      //   const type_ = dataType.filter((value, index) => {
      //     return value.value == type
      //   })

      //   if (type_[0]) {
      //     // const key_ = removePrefixThu(type_[0].label.toLocaleLowerCase(), typeV == 1 ? "thu" : "chi")
      //     const key_ = type_[0].label.toLocaleLowerCase();
      //     filKey = createSlug(typeV_ + " " + key_);
      //     console.log("filKeyfilKey ", filKey);


      //     const fildata = listDefaultAccount.filter((value, index) => {
      //       return value.code == filKey;
      //     })
      //     setListDefaultAccountFill(fildata[0]);
      //   }

    }
    return;
  }


  
  
  useEffect(() => {

    getListDefaultAccount();
    return () => {

    };
  }, []);

  const submitForm = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.id;
    }
    values.type=21;
    const detail = {};
    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        if (["price", "total", "total_price", "total_discount", "vat_price", "amount"].includes(key)) {
          detail[row][key] = parseNumberV_(values[x]);

        } else {
          detail[row][key] = values[x];
        }

      }

    }
    values.detail = Object.values(detail);
   

    const response = await StoreAccountantPurchaseRequest(values);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      setRecord(null);
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }


  return (
    <Card title={<div style={{ display: "flex", alignItems: "center" }}>
      <Link to={LINK.WEB_ACCOUNTANT}><Button size='small'><i className="fas fa-chevron-left"></i></Button></Link>
      <FolderViewOutlined style={{ color: Colors.pop2[3] }} />
      <span style={{ marginLeft: "10px", color: Colors.pop2[3] }} >Chứng từ NVK</span>

    </div>} bordered={false}
      style={{ width: "100%", margin: "0 auto" }}
      bodyStyle={{
        padding: "10px",
        // maxHeight: "90vh",
        // overflowY: "scroll"

      }}
      headStyle={{
        backgroundColor: Colors.colorgrey,
        display: "flex",
        minHeight: "30px",
        borderRadius: "3px"
      }}
    >
      <div className='w-100 m-auto row  p-0'>
        <div className='col-lg-12 col-md-12 col-sm-12 p-0 '>
          <Form name="formadd" onFinish={submitForm} ref={formRef}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 24 }}
          >


            <div className={`row ${parseInt(tab) == 1 ? "" : "d-none"}`}>
              <div className='col-lg-8 col-md-8 col-sm-8 '>
                <div className=' bg-white p-2'>

                  <Form.Item label="Diễn giải" name="note" className='m-0'>

                    <Input />
                  </Form.Item>

                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 '>
                <div className=' bg-white p-2'>
                  <Form.Item label="Ngày hoạch toán"
                    name="accounting_date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày hoạch toán là bắt buộc"
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }}
                    initialValue={defaultDate} className='m-0'
                  >

                    <DatePicker placeholder='ngày hoạch toán' style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item label="Ngày chứng từ"
                    name="document_date"
                    rules={[
                      {
                        required: true,
                        message: "Ngày chứng từ là bắt buộc"
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                    initialValue={defaultDate}

                  >

                    <DatePicker placeholder='ngày chứng từ' style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item label="Số chứng từ"
                    name="documnent_number"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Số chứng từ là bắt buộc"
                    //   },
                    // ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 14 }} className='m-0'
                  >

                    <Input placeholder="Số chứng từ" style={{ width: "100%" }} disabled />
                  </Form.Item>
                </div>
              </div>



            </div>



            <Planning formRef={formRef} listDefaultAccountFil={listDefaultAccountFil} setTotal={setTotal_} />
            <div className={`row`}>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền hàng: {formatNumber(total_.totalPrice)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tổng tiền chiết khấu: {formatNumber(total_.totalDiscount)}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className={`row`}>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền thuế: {formatNumber(total_.totalVat)}
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6'>
                    Tiền tiền thanh toán: {formatNumber(total_.total)}
                  </div>
                </div>
              </div>
            </div>
            <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <div className=' bg-white p-2'>
                  <Form.Item >
                    <div className="flex_">
                      <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

                      <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 bg-white'>


        </div>
      </div>
    </Card>
  )
}

export default Index
