import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Drawer, Empty, Form, Input, InputNumber, message, Popover, Select, Skeleton, Spin, Table, Tooltip, TreeSelect } from 'antd';
import "./../style.css"
import { ListAccountingAccountRequest, ListDefaultAccountRequest, ListDepartmentRequest, SearchProductsWarehouseNoDispatchRequest, StoreAccountantRequest, StoreAccountingAccountRequest, StoreIncreasseToolRequest } from '../../../../actions/indexWeb';
import { META } from '../../../../utils/constant';
import { DATA_GROUP_ACCOUNT, DATA_KEY_CODE_ACCOUNT, DATA_PRECIOUS } from '../../../../constants/DefaultData';
import {
  LoadingOutlined, SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from "../../../../constants/Links";

import { filAcounts, formatDate, formatDate_fm, formatDateVN, formatNumber, getParamsUrl, handleNumberInput, parseNumberV_ } from '../../../../service';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
const { RangePicker } = DatePicker;

const Index = ({getListGroupAccountant,record,dataDetail1,addRow,deleteRow,formRef,setVisibleListPurchase,purchase}) => {

  const [listDepartment, setListDepartment] = useState([]);

  const [meta, setMeta] = useState({ ...META });
  const [isloading, setIsloading] = useState(false);
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);
  const [listTKno, setListTKno] = useState([]);
  const [listTKco, setListTKco] = useState([]);

  const [listAccount, setListAccount] = useState([]);
  const [listDefaultAccountFil, setListDefaultAccountFill] = useState(null);
  const [listDefaultAccount, setListDefaultAccount] = useState([]);


  const tableRef = useRef(null);
  const newRowRef = useRef(null);
  
  const params = useParams();

 
  const defaultDate = moment().startOf('day');

 
  console.log("recordrecordrecordrecord ",record);

  
  const getListDefaultAccount = async () => {
    const response = await ListDefaultAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    console.log("responseresponse ", response);

    if (response.code == 200) {
      if (response.data) {

        setListDefaultAccount(response.data);

      }
    }


  }
  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }
  const filterTreeNode = (inputValue, treeNode) => {
    const { title, description, code } = treeNode;
    // console.log(treeNode.props.data);

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo title
      description.toLowerCase().includes(inputValue.toLowerCase()) || // Tìm kiếm theo description
      code.toString().includes(inputValue) // Tìm kiếm theo code
    );
  };
  useEffect(() => {
    filterAccount();

    return () => {

    };
  }, [listDefaultAccount, listAccount]);
  const filterAccount = () => {
    const fildata = listDefaultAccount.filter((value, index) => {
      return value.code == "phan-bo-ccdc";
    })
    setListDefaultAccountFill(fildata[0]);

    return;
  }
  useEffect(() => {


    processListAccount();
    return () => {

    };
  }, [listDefaultAccountFil]);
  const processListAccount = async () => {
    if (listDefaultAccountFil) {
      const listTKno_ = await filAcounts(listDefaultAccountFil, "tkno", listAccount);
      const listTKco_ = await filAcounts(listDefaultAccountFil, "tkco", listAccount);


      setListTKno(listTKno_);
      setListTKco(listTKco_);
      // setListTKvat(listTKvat_);




    }
  }

  const handleKeyDown = (e, isLastCell = false) => {


    // console.log(e);
    if (isLastCell && (e.key === 'Tab' || e.key === 'Enter')) {
      e.preventDefault();
      addRow();
    }

    if (!isLastCell && (e.key === 'Enter')) {
      e.preventDefault();
      addRow();

    }
  };

  
  
  const getListDepartment = async () => {
    const response = await ListDepartmentRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        // console.log(response.data.data);
        if (response.data.data) {

          setListDepartment(response.data.data);
        }
      }
    }


  }
  
  useEffect(() => {
    getListAccount();
    getListDepartment();
    getListDefaultAccount();

    return () => {

    };
  }, []);


  const onsubmit = async (values) => {
    setIsloading(true);
    if (record) {
      values.id = record.increase_tool_id;
    }
    values.increase_tool_id = record.id;
    if (values.price) {
      values.price =parseNumberV_(values.price.toString());
    }
    if (values.amount) {
      values.amount =parseNumberV_(values.amount.toString());
    }
    if (values.total_price_allocated) {
      values.total_price_allocated = parseNumberV_(values.total_price_allocated);
    }
   
    const total_price = values.price*values.amount;
    const periods = values.periods?? 0;


    const per_period =total_price / periods;
    const allocated = values.total_price_allocated/per_period;
    const remaining_period=periods-allocated;
    values.total_price=total_price;
    values.price_of_period=per_period;
    values.remaining_period=remaining_period;
    if(purchase?.code){
      values.purchase_id=purchase.code;
    }
    values.date= moment(values.date).format('YYYY-MM-DD');
    // values.accounting_date= moment(values.accounting_date).format('YYYY-MM-DD');
    const detail = {};
    for (const x in values) {
      const xarr = x.split("-");
      if (xarr[0] == "d") {
        let key = xarr[1];
        let row = xarr[2];
        if (!detail[row]) {
          detail[row] = {};
        }
        detail[row][key] = values[x];

      }

    }
    values.detail = Object.values(detail);

    // return;

    const response = await StoreIncreasseToolRequest(values);
    console.log("response ", response);
    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      getListGroupAccountant();
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const handleMoneyChange = (e, name) => {
    let inputValue = "";
    if (e.target.value) {
        inputValue = e.target.value // Loại bỏ ký tự không phải số
    } else {
        inputValue = e.toString() // Loại bỏ ký tự không phải số
    }
    const formattedValue= handleNumberInput(inputValue);

    formRef.current.setFieldsValue({
        [`${name}`]: formattedValue,
    });

};
  const handleProcessMoneyChange = (e, name) => {

    if (formRef.current) {
      // switch (name) {
      //     case "":

      //         break;

      //     default:
      //         break;
      // }
      const values = formRef.current.getFieldsValue();
      const price = parseNumberV_(values.price);
      const amount = parseNumberV_(values.amount);


      const total_price = price * amount;


      formRef.current.setFieldsValue({ total_price: formatNumber(total_price) })

    }
  };
  const proccessPeriods = (e) => {

    const values = formRef.current.getFieldsValue();
    const amount = values.amount ? parseNumberV_(values.amount.toString()) : 0;
    const total_price = values.total_price ? parseNumberV_(values.total_price)*amount  : 0;
    
    const total_price_allocated = values.total_price_allocated ? parseNumberV_(values.total_price_allocated)*amount : 0;
    const periods = values.periods?? 0;
    
    console.log("total_pricetotal_price ",total_price);
    
   

    const per_period =Math.round(total_price / periods);
    const allocated = Math.round(total_price_allocated/per_period);
    const remaining_period=Math.round(periods-allocated);
    // console.log("per_periodper_periodper_period ",total_price_allocated,per_period);
    // console.log("allocatedallocated ",allocated);
    formRef.current.setFieldsValue({ price_of_period: formatNumber(per_period), remaining_period: remaining_period})


  }

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (e) => {


    if (e.target.tagName != "TH") return;
    setIsDragging(true);
    setStartX(e.pageX - tableRef.current.offsetLeft);
    setScrollLeft(tableRef.current.scrollLeft);

  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };
  return (
    <Form name="form" ref={formRef} onFinish={onsubmit} style={{
      padding: "0px",
      width: "100%",
      // display: "flex"
    }}>
      <Form.Item label="Ngày ghi tăng" name="date"
        rules={[
          {
            required: true,
            message: "Ngày ghi tăng là bắt buộc"
          },
        ]} initialValue={defaultDate} >
        <DatePicker placeholder='Ngày ghi tăng' />
      </Form.Item>
      <Form.Item label="Mã /Tên CCDC">
        <Input.Group compact>
          <Form.Item name="products_warehouse_id" initialValue={record?.code}
            rules={[
              {
                required: true,
                message: "Mã CCDC là bắt buộc"
              },
            ]} style={{ width: "50%", margin: "0" }} >
            <Input placeholder='Mã' />
          </Form.Item>

          <Form.Item name="products_warehouse_name" initialValue={record?.title}
            rules={[
              {
                required: true,
                message: "Tên CCDC là bắt buộc"
              },
            ]}
            style={{ width: "50%", margin: "0" }}
          >
            <Input placeholder='Tên' />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="SL/ĐG/TT">
        <Input.Group compact>
          <Form.Item name="amount" initialValue={record?.amount}
            rules={[
              {
                required: true,
                message: "Số lượng là bắt buộc"
              },
            ]}
            style={{ width: "33%", margin: "0" }}
          >
            <Input min={1} placeholder='Số lượng' onChange={(e) => { handleMoneyChange(e, "amount"); handleProcessMoneyChange(e, "amount");proccessPeriods(e) }} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="price" initialValue={formatNumber(record?.price)}
            rules={[
              {
                required: true,
                message: "Đơn giá là bắt buộc"
              },
            ]}
            style={{ width: "33%", margin: "0" }}
          >
            <Input placeholder='Đơn giá' onChange={(e) => { handleMoneyChange(e, "price"); handleProcessMoneyChange(e, "price");proccessPeriods(e) }} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="total_price" initialValue={formatNumber(record?.price * record?.amount)}
            style={{ width: "33%", margin: "0" }}
          >
            <Input placeholder='Thành tiền' readOnly style={{ width: "100%" }} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Lý do" name="note" initialValue={record?.note}>
        <Input placeholder='Lý do' />
      </Form.Item>
         <Form.Item name="total_price_allocated" label="Giá trị đã phân bổ" rules={[
            {
              required: true,
              message: "Giá trị đã phân bổ"
            },
          ]} initialValue={0}  >
            <Input min={1} placeholder='Giá trị đã phân bổ' onChange={(e) => {handleMoneyChange(e, "total_price_allocated");proccessPeriods(e)}}  style={{ width:"100%" }} />
          </Form.Item>

      {/* <Form.Item label="Phân bổ">
        <Input.Group compact> */}
          <Form.Item name="periods" label="Số kỳ phân bổ" rules={[
            {
              required: true,
              message: "Số Kỳ PB là bắt buộc"
            },
          ]} initialValue={record?.periods} >
            <InputNumber min={1} placeholder='Số Kỳ PB' onChange={(e) => proccessPeriods(e)}  style={{ width:"100%" }} />
          </Form.Item>
          <Form.Item name={"remaining_period"}  label="Số kỳ phân bổ còn lại" rules={[
            {
              required: true,
              message: "Số Kỳ PB còn lại là bắt buộc"
            },
          ]} initialValue={record?.remaining_period}  >
            <InputNumber min={1} placeholder='Số Kỳ PB' onChange={(e) => proccessPeriods(e)}  style={{ width:"100%" }} />
          </Form.Item>
          <Form.Item name="price_of_period" label="Số tiền PB mỗi kỳ" initialValue={formatNumber(record?.price_of_period)}>
            <Input placeholder='Số tiền PB mỗi kỳ' readOnly style={{ width:"100%" }}  />
          </Form.Item>

          <Form.Item name="accounting_code" label="Tài khoản phân bổ"  initialValue={listTKco?.default} >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              treeData={listTKco?.listAccount}
              dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
              placeholder="Tài khoản"
              filterTreeNode={filterTreeNode}
              treeDefaultExpandAll



            />
          </Form.Item>
          <Form.Item name="purchase_id" label="nguồn gốc"   initialValue={record?.purchase_id}>
             <Input placeholder='Nguồn gốc' readOnly style={{ width:"100%" }} addonAfter={<span onClick={()=>setVisibleListPurchase(true)} style={{ cursor:"pointer" }}>Nguồn gốc</span>}  />
           
          </Form.Item>
        {/* </Input.Group>
      </Form.Item> */}
     
      <div className='fw-bold' >Thiết lập</div>
      <div className="table-responsive" style={{ height: "300px" }} ref={tableRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        <table className="table table-ledger table-sm table-hover" style={{ width: "max-content" }} >
          <thead style={{ position: "sticky", top: 0, zIndex: 1000, cursor: "all-scroll" }} >

            <tr className='noscroll'>
              <th className='p-1 text-center align-middle' ></th>
              <th className='p-1 text-center align-middle' id='tab1'  > Mã đơn vị</th>
              <th className='p-1 text-center align-middle'  >Tên đơn vị</th>
              <th className='p-1 text-center align-middle' >Số lượng</th>

              <th className='p-1 text-center align-middle'  >Tỉ lệ phân bổ</th>
              <th className='p-1 text-center align-middle' >TK chi phí</th>




            </tr>

          </thead>
          <tbody>
            {dataDetail1 && dataDetail1.map((row, index) => {
              const isLastRow = index === dataDetail1.length - 1;
              return <tr key={index}>
                <td>
                  <Button size='small' onClick={() => deleteRow(index)}><i className="fas fa-trash-alt text-danger"></i></Button>

                </td>
                <td>
                  <Form.Item name={"d-department_id-" + index}
                    rules={[
                      {
                        required: true,
                        message: "Mã bộ phận là bắt buộc"
                      },
                    ]}
                    style={{ width: "100px" }}
                    className='m-0'
                    initialValue={row?.department_id}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      options={(listDepartment || []).map((value, i) => {
                        return {
                          value: value.id,
                          label: value.code + "-" + value.name
                        }
                      })}

                      dropdownStyle={{ maxHeight: 400, minWidth: "400px", overflow: 'auto' }}
                      placeholder="Mã bộ phận"
                      // allowClear
                      // multiple
                      treeDefaultExpandAll
                      onKeyDown={(e) => { handleKeyDown(e, false) }}
                      onChange={(e) => {
                        const product = listDepartment.filter((item, i) => {
                          return item.id == e;

                        })
                        if (product[0]) {
                          if (formRef.current) {
                            formRef.current.setFieldsValue({ ["d-department_name-" + index]: product[0].name });
                          }
                        }


                      }}

                      ref={isLastRow ? newRowRef : null}
                    />

                  </Form.Item>
                </td>
                <td>
                  <Form.Item name={"d-department_name-" + index} style={{ width: "100%" }} className='m-0'
                    initialValue={row?.department_name}
                  >

                    <Input onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Tên bộ phận' />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item min={1} name={"d-amount-" + index} style={{ width: "100%" }} className='m-0'
                    initialValue={row?.amount}
                  >

                    <InputNumber onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='Số lượng' />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item name={"d-percent-" + index} style={{ width: "100%" }} className='m-0'
                    initialValue={row?.percent}
                  >

                    <InputNumber onKeyDown={(e) => { handleKeyDown(e, false) }} placeholder='tỉ lệ phân bổ' />
                  </Form.Item>
                </td>
                <td style={{ width: "200px" }}>
                  <Form.Item name={"d-accounting_code-" + index} style={{ width: "100%" }} className='m-0'
                    initialValue={listTKno?.default}
                  >

                    <TreeSelect
                      showSearch
                      style={{ width: '100%' }}
                      treeData={listTKno?.listAccount}
                      dropdownStyle={{ maxHeight: 400, minWidth: "250px", overflow: 'auto' }}
                      placeholder="Tk nợ"
                      filterTreeNode={filterTreeNode}
                      treeDefaultExpandAll
                      onKeyDown={(e) => { handleKeyDown(e, false) }}


                    />
                  </Form.Item>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      {record?.cost_allocation && <div className={`row`}>
              <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                <span className='text-danger fst-italic '>(*) Đã phân bổ chi phí Không thể sửa!</span>
              </div>
            </div>}
      <Form.Item noStyle style={{
        width: "100%"
      }}>
        <Button type="primary" htmlType="submit" style={{ width: "20%" }} loading={isLoadingSearch} disabled={record?.cost_allocation} >Lưu</Button>

      </Form.Item>

    </Form>
  )
}

export default Index

