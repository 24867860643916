import * as Types from "./../constants/TypesactionWeb";
import { PostWeb, UploadExcelFile, UploadFile, UploadPdfFile } from "./../utils/apiCaller";

// export const SetProductsData = (body) =>
// {

//   return (dispatch) =>
//   {

//     dispatch(SetProductsData_(body));

//   };
// };

// export const SetProductsData_ = (result) =>
// {
//   return {
//     type: Types.SET_PRODUCTS_DATA,
//     result,
//   };
// };
// prepaid expenses
export const StorePrepaidExpensesRequest =async (data) => {

  let url = `prepaid-expenses`
 

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// increase asset
export const StoreIncreasseAssetsRequest =async (data) => {

  let url = `increase-assets`
 

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// normal
export const SearchAccountantNormMaterialsRequest =async (body, data) => {

  let url = `accountant-norm-materials/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
 
};
export const StoreAccountantNormMaterialsRequest =async (data) => {

  let url = `accountant-norm-materials`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// increase tool
export const StoreIncreasseToolRequest =async (data) => {

  let url = `increase-tool`
 

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

// Allowance

export const ListAllowanceRequest =async (body) => {

  let url = `allowance?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreAllowanceRequest =async (data) => {

  let url = `allowance`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteAllowanceRequest =async (body) => {

  let url = `allowance/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

// salary

export const ListSalariesRequest =async (body,data) => {

  let url = `salary?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);
  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


// timekeeping

export const ListTimeKeepingRequest =async (body,data) => {

  let url = `employees-timekeeping/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);
  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreTimeKeepingRequest =async (data) => {

  let url = `employees-timekeeping/create-data`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const CreateDataTimeKeepingRequest =async (data) => {

  let url = `employees-timekeeping/create-data`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SetTimeKeepingRequest =async (data) => {

  let url = `employees-timekeeping/settime`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// department

export const ListEmployeesRequest =async (body) => {

  let url = `employees?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreEmployeesRequest =async (data) => {

  let url = `employees`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteEmployeesRequest =async (body) => {

  let url = `employees/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const ChangeStatusEmployeesRequest =async (body) => {

  let url = `employees/changestatus/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


// department

export const ListDepartmentRequest =async (body) => {

  let url = `department?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreDepartmentRequest =async (data) => {

  let url = `department`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteDepartmentRequest =async (body) => {

  let url = `department/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


// positions

export const ListPositionsRequest =async (body) => {

  let url = `positions?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StorePositionsRequest =async (data) => {

  let url = `positions`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeletePositionsRequest =async (body) => {

  let url = `positions/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

// purchase
export const SearchAccountantPurchaseRequest =async (body,data) => {

  let url = `accountant-purchase/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreAccountantPurchaseRequest =async (data) => {

  let url = `accountant-purchase`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


// default acccount

export const ListDefaultAccountRequest =async (body) => {

  let url = `accountant-default_account?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreDefaultAccountRequest =async (data) => {

  let url = `accountant-default_account`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteDefaultAccountRequest =async (body) => {

  let url = `accountant-default_account/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// accountant

export const ListDetermineCostPrepaidExRequest =async (data) => {

  let url = `prepaid-expenses/determine-cost`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ListPrepaidExRequest =async (data) => {

  let url = `prepaid-expenses/filter`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


export const ListTypeAssetsRequest =async (body,data) => {

  let url = `accountant-type-assets/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreTypeAssetsRequest =async (data) => {

  let url = `accountant-type-assets`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteTypeAssetsRequest =async (body) => {

  let url = `accountant-type-assets/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ListDetermineCostAssetRequest =async (data) => {

  let url = `increase-assets/determine-cost`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


export const StoreCostAllocationRequest =async (data) => {

  let url = `cost-allocation`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const ListDetermineCostRequest =async (data) => {

  let url = `increase-tool/determine-cost`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const ListAccountantBankRequest =async (body) => {

  let url = `accountant-bank?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreAccountantBankRequest =async (data) => {

  let url = `accountant-bank`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteAccountantBankRequest =async (body) => {

  let url = `accountant-bank/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};



export const ListAccountantInvoiceTemplateRequest =async (body) => {

  let url = `accountant-invoice-template?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreAccountantInvoiceTemplateRequest =async (data) => {

  let url = `accountant-invoice-template`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteAccountantInvoiceTemplateRequest =async (body) => {

  let url = `accountant-invoice-template/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ListAccountingAccountRequest =async (body) => {

  let url = `accounting-account?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const StoreAccountingAccountRequest =async (data) => {

  let url = `accounting-account`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const UpdateBalanceAccountRequest =async (data) => {

  let url = `accounting-account/update-balance`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const StoreAccountantRequest =async (data) => {

  let url = `accountant`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ListAccountantRequest =async (body,data) => {

  let url = `accountant/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const DeleteAccountantRequest =async (body) => {

  let url = `accountant/deleted/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const LockAccountantRequest =async (body) => {

  let url = `accountant/lock/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const LockAccountantMultipleRequest =async (body) => {

  let url = `accountant/lock-multiple`
  // console.log(url);

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const UnLockAccountantRequest =async (body) => {

  let url = `accountant/unlock/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const UnLockAccountantMultipleRequest =async (body) => {

  let url = `accountant/unlock-multiple`
  // console.log(url);

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const CopyAccountantRequest =async (body) => {

  let url = `accountant/copy/${body.id}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const GroupAccountantRequest =async (body,data) => {

  let url = `accountant/group?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


export const CarryoverAccountantRequest =async (body) => {

  let url = `accountant/carryover`
  // console.log(url);

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const CloseAccountantRequest =async (body) => {

  let url = `accountant/close`
  // console.log(url);

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const GetcompanyTaxcodeRequest =async (body) => {

  let url = `get-company-taxcode/${body.taxcode}`
  // console.log(url);

  try {
    const response = await PostWeb(url, "", "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// accountant
// products
export const ExportShopeeBillVatRequest = (data) => {

  let url = `export/bill-vat-shopee`
  // console.log(url);

  return PostWeb(url, data, "POST");
};
export const ExportShopeeBillVatMultipleRequest = (data) => {

  let url = `export/bill-vat-shopee-multiple`
  // console.log(url);

  return PostWeb(url, data, "POST");
};

export const ExportLazadaBillVatRequest = (data) => {

  let url = `export/bill-vat-lazada`
  // console.log(url);

  return PostWeb(url, data, "POST");
};
export const ExportLazadaBillVatMultipleRequest = (data) => {

  let url = `export/bill-vat-lazada-multiple`
  // console.log(url);

  return PostWeb(url, data, "POST");
};

export const GetLinkBillVatMultipleRequest = (data) => {

  let url = `export/get-link-bill`
  // console.log(url);

  return PostWeb(url, data, "POST");
};
export const ExportTiktokBillVatMultipleRequest = (data) => {

  let url = `export/bill-vat-tiktok-multiple`
  // console.log(url);

  return PostWeb(url, data, "POST");
};
export const ExportTiktokBillVatRequest = (data) => {

  let url = `export/bill-vat-tiktok`
  // console.log(url);

  return PostWeb(url, data, "POST");
};

export const SignBillVatRequest = (data) => {

  let url = `export/sign-bill-vat`
  // console.log(url);

  return PostWeb(url, data, "POST");
};

export const ExportProductsRequest = (body, data) => {

  let url = `export/products?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  console.log(url);

  return PostWeb(url, data, "POST").then(response => {
    const contentDisposition = response.headers.get("Content-Disposition");
    console.log("contentDisposition ", contentDisposition);
    return response.blob().then(blob => {

      return {
        blob: blob,
        filename: getFileNameFromContentDisposition(contentDisposition)
      };
    });
  });
};
const getFileNameFromContentDisposition = (contentDisposition) => {
  const filename = contentDisposition
    ? contentDisposition.split(';').find(n => n.includes('filename=')).replace('filename=', '').trim()
    : 'download.xlsx';
  return filename;
}

// export const ExportProducts = (result) =>
//   {
//     return {
//       type: Types.SYNC_PRODUCTS_TIKTOK,
//       result,
//     };
//   };


export const resetCacheRequest = (data) => {

  let url = `reset-cache-web`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {


        dispatch(resetCache(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const resetCache = (result) => {
  return {
    type: Types.RESET_CACHE,
    result,
  };
};

export const getListCacheRequest = () => {

  let url = `get-cache-key-web`

  return (dispatch) => {

    return PostWeb(url, null, "GET")
      .then(response => response.text())
      .then(result => {
        console.log(result);

        dispatch(getListCache(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const getListCache = (result) => {
  return {
    type: Types.GET_CACHE_KEY,
    result,
  };
};


export const resetPdfStreamRequest = (body) => {
  let url = `pdf-show`
  return (dispatch) => {

    return dispatch(getPdfStream(""));

  };
};

export const getPdfStreamRequest = (body) => {
  let url = `pdf-show`
  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.blob())
      .then(result => {
        // console.log("resultresultresultresultresult",result);
        dispatch(getPdfStream(result));

      }).catch(error1 => console.log('error', error1));

  };
};
export const getPdfStream = (result) => {
  return {
    type: Types.GET_PDF_STREAM,
    result,
  };
};
export const getPdfDownloadRequest = (body) => {
  let url = `pdf/dowload`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(getPdfDownload(result));

      }).catch(error1 => console.log('error', error1));

  };
};
export const getPdfDownload = (result) => {
  return {
    type: Types.GET_PDF_DOWLOAD,
    result,
  };
};
export const getPdfRequest = (body) => {
  let url = `pdf`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(getPdf(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const getPdf = (result) => {
  return {
    type: Types.GET_PDF,
    result,
  };
};


export const confirmNotificationRequest = (id) => {

  let url = `notificationdata/confirm/${id}`

  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        // dispatch(syncProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
// shopee
export const ListAttributeShopeeRequest = (body) => {
  let url = `shopee/product/get-attributes`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {
        //  console.log("resultresultresult",result);
        dispatch(ListAttributeByProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchProductsShopeeRequest = (body, data) => {

  let url = `shopee/product/getlist?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&code=${body.code}&next_page_token=${body.next_page_token}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresult ", JSON.parse(result));
        dispatch(SearchProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


export const SyncProductsShopeeQueueRequest = (body, data) => {

  let url = `shopee/product/syncproductqueue?code=${body.code}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(syncProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ShopeeCategoriesRequest = (data = null) => {

  let url = `shopee/product/get-categories`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("LazadaCategoriesRequestLazadaCategoriesRequest ",JSON.parse(result));

        dispatch(ShopeeCategories(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ShopeeCategories = (result) => {
  return {
    type: Types.LIST_LAZADA_CATEGORIES,
    result,
  };
};

export const ShopeeBrandsNoDispatchRequest = async (data = null) => {

  let url = `shopee/product/get-brands`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ShopeeBrandsRequest = (data = null) => {

  let url = `shopee/product/get-brands`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(ShopeeBrands(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ShopeeBrands = (result) => {
  return {
    type: Types.LIST_LAZADA_BRANDS,
    result,
  };
};


export const ShopeeAtttributesNoDispatchRequest = async (data = null) => {

  let url = `shopee/product/get-attributes`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ShopeeAtttributesRequest = (data = null) => {

  let url = `shopee/product/get-attributes`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("JSON.parse(result)JSON.parse(result) ", JSON.parse(result));
        dispatch(ShopeeAtttributes(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ShopeeAtttributes = (result) => {
  return {
    type: Types.LIST_TIKTOK_ATTRIBUTES,
    result,
  };
};
export const GetlogisticListShopeeNodisPatchRequest =async (data) => {

  let url = `shopee-orders/get-logistic`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SyncProductsToShopeeNodisPatchRequest =async (data) => {

  let url = `shopee/product/syncproducttotiktok`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SyncProductsToShopeeOneRequest = (data) => {

  let url = `shopee/product/syncproducttotiktokone`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("resultresultresult",result);
        dispatch(SyncProductsToShopee(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncProductsToShopee = (result) => {
  return {
    type: Types.SYNC_PRODUCTS_TO_LAZADA,
    result,
  };
};
export const SearchShopeeOrdersRequest = (body, data) => {
  let url = `shopee-orders/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&page_size=${body.page_size}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncShopeeOrdersRequest = (body, data) => {
  let url = `shopee-orders/syncorder?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&code=${body.code}&page_size=${body.page_size}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SyncTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SyncVariationNodisPatchRequest =async (data) => {

  let url = `shopee/product/syncVariation`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// shopee
// lazada
export const ListAttributeZaladaRequest = (body) => {
  let url = `lazada/product/get-attributes`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {
        //  console.log("resultresultresult",result);
        dispatch(ListAttributeByProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchProductsLazadaRequest = (body, data) => {

  let url = `lazada/product/getlist?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&code=${body.code}&next_page_token=${body.next_page_token}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresult ", JSON.parse(result));
        dispatch(SearchProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


export const SyncProductsLazadaQueueRequest = (body, data) => {

  let url = `lazada/product/syncproductqueue?code=${body.code}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(syncProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const LazadaCategoriesRequest = (data = null) => {

  let url = `lazada/product/get-categories`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("LazadaCategoriesRequestLazadaCategoriesRequest ",JSON.parse(result));

        dispatch(LazadaCategories(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const LazadaCategories = (result) => {
  return {
    type: Types.LIST_LAZADA_CATEGORIES,
    result,
  };
};
export const LazadaBrandsRequest = (data = null) => {

  let url = `lazada/product/get-brands`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(LazadaBrands(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const LazadaBrands = (result) => {
  return {
    type: Types.LIST_LAZADA_BRANDS,
    result,
  };
};


export const LazadaAtttributesNoDispatchRequest = async (data = null) => {

  let url = `lazada/product/get-attributes`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const LazadaAtttributesRequest = (data = null) => {

  let url = `lazada/product/get-attributes`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("JSON.parse(result)JSON.parse(result) ", JSON.parse(result));
        dispatch(LazadaAtttributes(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const LazadaAtttributes = (result) => {
  return {
    type: Types.LIST_LAZADA_ATTRIBUTES,
    result,
  };
};

export const SyncProductsToLazadaNodisPatchRequest =async (data) => {

  let url = `lazada/product/syncproducttotiktok`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SyncProductsToLazadaOneRequest = (data) => {

  let url = `lazada/product/syncproducttotiktokone`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("resultresultresult",result);
        dispatch(SyncProductsToLazada(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncProductsToLazada = (result) => {
  return {
    type: Types.SYNC_PRODUCTS_TO_LAZADA,
    result,
  };
};
export const SearchLazadaOrdersRequest = (body, data) => {
  let url = `lazada-orders/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&page_size=${body.page_size}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncLazadaOrdersRequest = (body, data) => {
  let url = `lazada-orders/syncorder?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&code=${body.code}&page_size=${body.page_size}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SyncTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
// tiktok
export const SyncProductsTiktokQueueRequest = (body, data) => {

  let url = `tiktok/product/syncproductqueue?code=${body.code}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(syncProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncProductsTiktokRequest = (body, data) => {

  let url = `tiktok/product/syncpro?code=${body.code}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(syncProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const syncProducts = (result) => {
  return {
    type: Types.SYNC_PRODUCTS_TIKTOK,
    result,
  };
};


export const SyncProductsToTiktokOneRequest = (data) => {

  let url = `tiktok/product/syncproducttotiktokone`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("resultresultresult",result);
        dispatch(SyncProductsToTiktok(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncProductsToTiktokNodisPatchRequest =async (data) => {

  let url = `tiktok/product/syncproducttotiktok`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SyncProductsToTiktokRequest = (data) => {

  let url = `tiktok/product/syncproducttotiktok`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SyncProductsToTiktok(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ResetSyncProductsToTiktok = (data) => {


  return (dispatch) => {

    return dispatch(SyncProductsToTiktok(null));
  }
};
export const SyncProductsToTiktok = (result) => {
  return {
    type: Types.SYNC_PRODUCTS_TO_TIKTOK,
    result,
  };
};
export const SearchProductsTiktokRequest = (body, data) => {

  let url = `tiktok/product/getlist?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&code=${body.code}&next_page_token=${body.next_page_token}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const TiktokCategoriesRequest = (data = null) => {

  let url = `tiktok/product/get-categories`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(TiktokCategories(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const TiktokCategories = (result) => {
  return {
    type: Types.LIST_TIKTOK_CATEGORIES,
    result,
  };
};
export const TiktokBrandsRequest = (data = null) => {

  let url = `tiktok/product/get-brands`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(TiktokBrands(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const TiktokBrands = (result) => {
  return {
    type: Types.LIST_TIKTOK_BRANDS,
    result,
  };
};

export const TiktokAtttributesNoDispatchRequest =async (data = null) => {

  let url = `tiktok/product/get-attributes`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


export const TiktokAtttributesRequest = (data = null) => {

  let url = `tiktok/product/get-attributes`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(TiktokAtttributes(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const TiktokAtttributes = (result) => {
  return {
    type: Types.LIST_TIKTOK_ATTRIBUTES,
    result,
  };
};


export const createCustomBrandTitokRequest = (data = null) => {

  let url = `tiktok/product/create-brands`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(createCustomBrandTitok(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const createCustomBrandTitok = (result) => {
  return {
    type: Types.CREATE_CUSTOM_BRAND_TITOK,
    result,
  };
};
export const SearchProductsNodisPatchRequest =async (body, data) => {

  let url = `products/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
 
};
export const SearchProductsRequest = (body, data) => {

  let url = `products/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchProductsDetailRequest = (body, data) => {

  let url = `products/filterdetail?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const resetProductsRequest = () => {

  return (dispatch) => {

    return dispatch(ListProducts({}));

  };
};
export const SearchProducts = (result) => {
  return {
    type: Types.LIST_PRODUCTS,
    result,
  };
};
export const ListProductsRequest = (body) => {
  let url = `products?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `products?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ResetListProductsRequest = () => {

  return (dispatch) => {

    return dispatch(ListProducts({ data: [] }));

  };
};
export const ProductByIdRequest = (body) => {
  var url = `products/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


export const duplicateProductRequest = (body) => {
  var url = `products/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const resetListProductRequest = () => {

  return (dispatch) => {

    return dispatch(ListProducts({ data: [] }));

  }
};
export const ListProducts = (result) => {
  return {
    type: Types.LIST_PRODUCTS,
    result,
  };
};
export const StoreProductsRequest = (body, id = '') => {
  let endpoint = 'products';
  let method = "POST";
  if (id) {
    endpoint = `products/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreProductsMenuhomeRequest = (body) => {
  let endpoint = 'products/update-menuhome';
  let method = "POST";
  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        dispatch(StoreProductsMenuhome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ResetStoreProductsMenuhomeRequest = () => {

  return (dispatch) => {

    return dispatch(StoreProductsMenuhome(null));

  };
};
export const StoreProductsMenuhome = (result) => {
  return {
    type: Types.STORE_PRODUCTS_MENU_HOME,
    result,
  };
};


export const DeleteMutpleProductsRequest = (body) => {
  let endpoint = `products/delete-mutiple`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const DeleteProductsRequest = (body, id = '') => {
  let endpoint = `products/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const HiddenProductsRequest = (body, id = '') => {
  let endpoint = `products/hidden/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreProducts = (result) => {
  return {
    type: Types.STORE_PRODUCTS,
    result,
  };
};

export const ResetStoreProducts = () => {
  return (dispatch) => {

    dispatch(StoreProducts(""));


  };
};
export const ListAttributeByProductsRequest = (body) => {
  let url = `products/getattribute`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {
        //  console.log("resultresultresult",result);
        dispatch(ListAttributeByProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const resetListAttributeByProductsRequest = (body) => {


  return (dispatch) => {

    return dispatch(ListAttributeByProducts({ data: {}, code: 200 }));

  };
};
export const ListAttributeByProducts = (result) => {
  return {
    type: Types.LIST_ATTRIBUTE_BY_PRODUCTS,
    result,
  };
};
// order


export const duplicateOrdersRequest = (body) => {
  var url = `orders/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchOrdersRequest = (body, data) => {
  let url = `orders/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchOrders = (result) => {
  return {
    type: Types.LIST_ORDERS,
    result,
  };
};

export const ListOrdersRequest = (body) => {
  let url = `orders?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `orders?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  else if (body.id) {
    url = `orders/${body.id}`;
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListOrders = (result) => {
  return {
    type: Types.LIST_ORDERS,
    result,
  };
};
export const OrderByIdRequest = (body) => {
  var url = `orders/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(OrderById(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const OrderById = (result) => {
  return {
    type: Types.ORDER_BY_ID,
    result,
  };
};

export const ResetOrderById = () => {
  return (dispatch) => {

    return dispatch(OrderById({}));

  };
};

export const DeleteMultipleOrdersRequest = (body) => {
  let endpoint = `orders/delete-mutiple`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const DeleteOrdersRequest = (id = '') => {
  let endpoint = `orders/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, "", method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreOrdersRequest = (body, id = '') => {
  let endpoint = 'orders';
  let method = "POST";
  if (id) {
    endpoint = `orders/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log(JSON.parse(result));
        console.log('====================================');
        dispatch(StoreOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreOrders = (result) => {
  return {
    type: Types.STORE_ORDERS,
    result,
  };
};

export const ResetStoreOrders = () => {
  return (dispatch) => {

    dispatch(StoreOrders(""));


  };
};

export const UpdateStatusOrdersRequest = (body) => {
  let endpoint = `orders/updatestatus`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const updateInvoiceTiktok = async(body) => {
  let url = `tiktok-orders/get-invoice_info`

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
// order
export const updateOnlyOrderTiktok = async(body) => {
  let url = `tiktok-orders/sync-only-order`

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const updateExcelTiktok = async(body,params,data) => {
  let url = `tiktok-orders/read-excel?type=${params.type}`

  try {
    const response = await UploadExcelFile(url, body,data);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const updateBillTiktok = async(body) => {
  let url = `read-pdf-tiktok`

  try {
    const response = await UploadPdfFile(url, body);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const updatePhoneNumberTiktok = async(body) => {
  let url = `tiktok-orders/update-phone`

  try {
    const response = await PostWeb(url, body, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const duplicateTiktokOrdersRequest = (body) => {
  var url = `orders/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncTiktokOrdersRequest = (body, data) => {
  let url = `tiktok-orders/syncorder?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&code=${body.code}&page_size=${body.page_size}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SyncTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SyncTiktokOrders = (result) => {
  return {
    type: Types.SYNC_TIKTOK_ORDERS,
    result,
  };
};
export const SearchTiktokOrdersRequest = (body, data) => {
  let url = `tiktok-orders/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&page_size=${body.page_size}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchTiktokOrders = (result) => {
  return {
    type: Types.LIST_TIKTOK_ORDERS,
    result,
  };
};

export const ListTiktokOrdersRequest = (body) => {
  let url = `tiktok-orders?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `tiktok-orders?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  else if (body.id) {
    url = `tiktok-orders/${body.id}`;
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListTiktokOrders = (result) => {
  return {
    type: Types.LIST_TIKTOK_ORDERS,
    result,
  };
};
export const TiktokOrdersByIdRequest = (body) => {
  var url = `tiktok-orders/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(TiktokOrdersById(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const TiktokOrdersById = (result) => {
  return {
    type: Types.TIKTOK_ORDER_BY_ID,
    result,
  };
};

export const ResetTiktokOrdersById = () => {
  return (dispatch) => {

    return dispatch(TiktokOrdersById({}));

  };
};
export const StoreTiktokOrdersRequest = (body, id = '') => {
  let endpoint = 'tiktok-orders';
  let method = "POST";
  if (id) {
    endpoint = `orders/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreTiktokOrders(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreTiktokOrders = (result) => {
  return {
    type: Types.STORE_TIKTOK_ORDERS,
    result,
  };
};

export const ResetStoreTiktokOrders = () => {
  return (dispatch) => {

    dispatch(StoreTiktokOrders(""));


  };
};



// statustransport
export const ListStatusTransportByIdRequest = (body) => {
  var url = `statustransport/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListStatusTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchStatusTransportRequest = (body, data) => {
  let url = `statustransport/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log(result);
        dispatch(SearchStatusTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchStatusTransport = (result) => {
  return {
    type: Types.LIST_STATUS_TRANSPORT,
    result,
  };
};
export const ListStatusTransportRequest = (body) => {

  let url = `statustransport?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `statustransport?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListStatusTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListStatusTransport = (result) => {
  return {
    type: Types.LIST_STATUS_TRANSPORT,
    result,
  };
};
export const StoreStatusTransportRequest = (body, id = '') => {
  let endpoint = 'statustransport';
  let method = "POST";
  if (id) {
    endpoint = `statustransport/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        // console.log('====================================');
        // console.log(result);
        // console.log('====================================');
        dispatch(StoreStatusTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreStatusTransport = (result) => {
  return {
    type: Types.STORE_STATUS_TRANSPORT,
    result,
  };
};

export const ResetStoreStatusTransport = () => {
  return (dispatch) => {

    dispatch(StoreStatusTransport(""));


  };
};


// statustransportType

export const ListStatusTransportTypeByIdRequest = (body) => {
  var url = `statustransporttype/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListStatusTransportType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchStatusTransportTypeRequest = (body, data) => {
  let url = `statustransporttype/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchStatusTransportType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchStatusTransportType = (result) => {
  return {
    type: Types.LIST_STATUS_TRANSPORT_TYPE,
    result,
  };
};
export const ListStatusTransportTypeRequest = (body) => {
  let url = `statustransporttype?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `statustransporttype?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListStatusTransportType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListStatusTransportType = (result) => {
  return {
    type: Types.LIST_STATUS_TRANSPORT_TYPE,
    result,
  };
};
export const StoreStatusTransportTypeRequest = (body, id = '') => {
  let endpoint = 'statustransporttype';
  let method = "POST";
  if (id) {
    endpoint = `statustransporttype/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log(JSON.parse(result));
        console.log('====================================');
        dispatch(StoreStatusTransportType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreStatusTransportType = (result) => {
  return {
    type: Types.STORE_STATUS_TRANSPORT_TYPE,
    result,
  };
};

export const ResetStoreStatusTransportType = () => {
  return (dispatch) => {

    dispatch(StoreStatusTransportType(""));


  };
};
// table




// category
export const SearchCategoryRequestNotDispatch = async (body, data) => {
  let url = `categories/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`;

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ResetSearchCategoryRequest = () => {

  return (dispatch) => {

    dispatch(ListCategory({ data: [] }));
  };
};
export const SearchCategoryRequest = (body, data) => {
  let url = `categories/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("resultresultresult ",JSON.parse(result));
        dispatch(ListCategory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreCategoryMultipleRequest = (data) => {
  let endpoint = 'categories/multiple';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        // console.log('====================================');
        // console.log(JSON.parse(result));
        // console.log('====================================');
        dispatch(StoreCategory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreCategoryRequest = (data, id = "") => {
  let endpoint = 'categories';
  let method = "POST";
  if (id) {
    endpoint = `categories/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)

      .then(response => response.text())
      .then(result => {
        // console.log('====================================');
        // console.log("resultresultresult", result);
        // console.log('====================================');
        dispatch(StoreCategory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreCategory = (result) => {
  return {
    type: Types.STORE_CATEGORY,
    result,
  };
};
export const ResetStoreCategory = () => {
  return (dispatch) => {

    dispatch(StoreCategory(""));


  };
};
export const DeleteCategoryRequest = (id) => {
  let endpoint = `categories/${id}`;
  let method = "DElETE";

  if (Array.isArray(id)) {
    endpoint = `categories/destroymuti`
    method = "POST";
  }
  return (dispatch) => {

    return PostWeb(endpoint, id, method)
      .then(response => response.text())
      .then(result => {


        dispatch(DeleteCategory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const DeleteCategory = (result) => {
  return {
    type: Types.DELETE_CATEGORY,
    result,
  };
};
export const ResetDeleteCategory = () => {
  return (dispatch) => {

    dispatch(DeleteCategory(""));


  };
};


export const ListCategoryRequest = (body) => {
  let url = `categories?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `categories?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListCategory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListCategory = (result) => {
  return {
    type: Types.LIST_CATEGORY,
    result,
  };
};


// //////////////


// menhome

export const SearchMenuHomeRequest = (body, data) => {
  let url = `menuhome/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("resultresult", JSON.parse(result));
        dispatch(ListMenuHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreMenuHomeMultipleRequest = (data) => {
  let endpoint = 'menuhome/multiple';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log(JSON.parse(result));
        console.log('====================================');
        dispatch(StoreMenuHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreMenuHomeRequest = (data, id = "") => {
  let endpoint = 'menuhome';
  let method = "POST";
  if (id) {
    endpoint = `menuhome/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)

      .then(response => response.text())
      .then(result => {
        // console.log('====================================');
        // console.log("resultresultresult", result);
        // console.log('====================================');
        dispatch(StoreMenuHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreMenuHome = (result) => {
  return {
    type: Types.STORE_MENUHOME,
    result,
  };
};
export const ResetStoreMenuHome = () => {
  return (dispatch) => {

    dispatch(StoreMenuHome(""));


  };
};
export const DeleteMenuHomeRequest = (id) => {
  let endpoint = `menuhome/${id}`;
  let method = "DElETE";

  if (Array.isArray(id)) {
    endpoint = `menuhome/destroymuti`
    method = "POST";
  }
  return (dispatch) => {

    return PostWeb(endpoint, id, method)
      .then(response => response.text())
      .then(result => {


        dispatch(DeleteMenuHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const DeleteMenuHome = (result) => {
  return {
    type: Types.DELETE_MENUHOME,
    result,
  };
};
export const ResetDeleteMenuHome = () => {
  return (dispatch) => {

    dispatch(DeleteMenuHome(""));


  };
};


export const ListMenuHomeRequest = (body) => {
  let url = `menuhome?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `menuhome?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListMenuHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListMenuHome = (result) => {
  return {
    type: Types.LIST_MENUHOME,
    result,
  };
};


// //////////////

// ////////////////////////
export const StoreClassifysMultipleRequest = (data) => {
  let endpoint = 'classify/multiple';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log("okok", JSON.parse(result));
        console.log('====================================');
        dispatch(StoreClassify(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListClassifysByIdRequest = (body) => {
  var url = `classify/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListClassifys(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListClassifysRequest = (body) => {
  let url = `classify?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `classify?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListClassifys(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListClassifys = (result) => {
  return {
    type: Types.LIST_CLASSIFYS,
    result,
  };
};

export const StoreClassifyRequest = (data, id = "") => {
  let endpoint = 'classify';
  let method = "POST";
  if (id) {
    endpoint = `classify/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreClassify(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreClassify = (result) => {
  return {
    type: Types.STORE_CLASSIFYS,
    result,
  };
};
export const ResetStoreClassify = () => {
  return (dispatch) => {

    dispatch(StoreClassify(""));


  };
};



// NumberOfPage 

export const ListNumberOfPageRequest = (body) => {
  let url = `numberofpage?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `numberofpage?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListNumberOfPage(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListNumberOfPage = (result) => {
  return {
    type: Types.LIST_NUMBER_OF_PAGE,
    result,
  };
};

export const StoreNumberOfPageRequest = (data, id = "") => {
  let endpoint = 'numberofpage';
  let method = "POST";
  if (id) {
    endpoint = `numberofpage/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreNumberOfPage(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreNumberOfPage = (result) => {
  return {
    type: Types.STORE_NUMBER_OF_PAGE,
    result,
  };
};
export const ResetStoreNumberOfPage = () => {
  return (dispatch) => {

    dispatch(StoreNumberOfPage(""));


  };
};





// infotransport

export const ListInfoTransportRequest = (body) => {
  let url = `infotransport?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `infotransport?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListInfoTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListInfoTransport = (result) => {
  return {
    type: Types.LIST_INFOTRANSPORT,
    result,
  };
};

export const StoreInfoTransportRequest = (data, id = "") => {
  let endpoint = 'infotransport';
  let method = "POST";
  if (id) {
    endpoint = `infotransport/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreInfoTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreInfoTransport = (result) => {
  return {
    type: Types.STORE_INFOTRANSPORT,
    result,
  };
};
export const ResetStoreInfoTransport = () => {
  return (dispatch) => {

    dispatch(StoreInfoTransport(""));


  };
};

export const InfoTransportByIdRequest = (body) => {
  let url = `infotransport/${body.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListInfoTransport(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


// Region

export const ListRegionByIdRequest = (body) => {
  var url = `region/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListRegion(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListRegionRequest = (body) => {
  let url = `region?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `region?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListRegion(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListRegion = (result) => {
  return {
    type: Types.LIST_REGION,
    result,
  };
};

export const StoreRegionRequest = (data, id = "") => {
  let endpoint = 'region';
  let method = "POST";
  if (id) {
    endpoint = `region/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreRegion(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreRegion = (result) => {
  return {
    type: Types.STORE_REGION,
    result,
  };
};
export const ResetStoreRegion = () => {
  return (dispatch) => {

    dispatch(StoreRegion(""));


  };
};




// province
export const ListProvincesNodispatchRequest = async (body) => {
  let url = `provinces?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `provinces?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
 
};
export const ListProvincesByIdRequest = (body) => {
  var url = `provinces/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListProvinces(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListProvincesRequest = (body) => {
  let url = `provinces?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `provinces?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListProvinces(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListProvinces = (result) => {
  return {
    type: Types.LIST_PROVINCES,
    result,
  };
};

export const StoreProvincesRequest = (data, id = "") => {
  let endpoint = 'provinces';
  let method = "POST";
  if (id) {
    endpoint = `provinces/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreProvinces(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreProvinces = (result) => {
  return {
    type: Types.STORE_PROVINCES,
    result,
  };
};
export const ResetStoreProvinces = () => {
  return (dispatch) => {

    dispatch(StoreProvinces(""));


  };
};



// ecommerce_config
export const UpdateActiveEcommerceConfigRequest = (data) => {
  let endpoint = 'ecommerceconfig/update-active';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresultresultresult ",result);
        
        dispatch(StoreEcommerceConfig(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


export const DeletedEcommerceConfigRequest = (data) => {
  let endpoint = 'ecommerceconfig/deleted';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreEcommerceConfig(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListEcommerceConfigByCodeRequest = (body) => {
  var url = `ecommerceconfig/code?code=${body.code}`;

  return (dispatch) => {

    return PostWeb(url, "", "post")
      .then(response => response.text())
      .then(result => {
        dispatch(ListEcommerceConfigByCode(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListEcommerceConfigByCode = (result) => {
  return {
    type: Types.LIST_ECOMMERCE_BY_CODE,
    result,
  };
};

export const ListEcommerceConfigByIdRequest = (body) => {
  var url = `ecommerceconfig/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListEcommerceConfigById(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListEcommerceConfigById = (result) => {
  return {
    type: Types.LIST_ECOMMERCE_BY_ID,
    result,
  };
};
export const ListEcommerceConfigRequest = (body,data=null) => {
  let url = `ecommerceconfig/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `ecommerceconfig/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log(" ListEcommerceConfigRequestListEcommerceConfigRequestListEcommerceConfigRequest  ",JSON.parse(result));
        
        dispatch(ListEcommerceConfig(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListEcommerceConfig = (result) => {
  return {
    type: Types.LIST_ECOMMERCE,
    result,
  };
};

export const StoreEcommerceConfigRequest = (data, id = "") => {
  let endpoint = 'ecommerceconfig';
  let method = "POST";
  if (id) {
    endpoint = `ecommerceconfig/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreEcommerceConfig(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreEcommerceConfig = (result) => {
  return {
    type: Types.STORE_ECOMMERCE,
    result,
  };
};
export const ResetStoreEcommerceConfig = () => {
  return (dispatch) => {

    dispatch(StoreEcommerceConfig(""));


  };
};

export const refreshTokenEcommerceConfigRequest = (id) => {
  let endpoint = `ecommerceconfig/refresh/${id}`;
  let method = "GET";

  return (dispatch) => {

    return PostWeb(endpoint, "", method)
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log("JSON.parse(result)", JSON.parse(result));
        console.log('====================================');
        dispatch(RefreshEcommerceConfig(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const RefreshEcommerceConfig = (result) => {
  return {
    type: Types.REFRESH_ECOMMERCE,
    result,
  };
};


// ecommerce_type

export const ListEcommerceTypeByCodeRequest = (body) => {
  var url = `ecommercetype/code?code=${body.code}`;

  return (dispatch) => {

    return PostWeb(url, "", "post")
      .then(response => response.text())
      .then(result => {
        dispatch(ListEcommerceTypeByCode(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListEcommerceTypeByCode = (result) => {
  return {
    type: Types.LIST_ECOMMERCE_TYPE_BY_CODE,
    result,
  };
};

export const ListEcommerceTypeByIdRequest = (body) => {
  var url = `ecommercetype/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListEcommerceTypeById(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListEcommerceTypeById = (result) => {
  return {
    type: Types.LIST_ECOMMERCE_TYPE_BY_ID,
    result,
  };
};
export const ListEcommerceTypeRequest = (body,data=null) => {

  
  
  let url = `ecommercetype/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `ecommercetype/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("ListEcommerceTypeRequestListEcommerceTypeRequestListEcommerceTypeRequest ",JSON.parse(result));
        
        dispatch(ListEcommerceType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListEcommerceType = (result) => {
  return {
    type: Types.LIST_ECOMMERCE_TYPE,
    result,
  };
};

export const StoreEcommerceTypeRequest = (data, id = "") => {
  let endpoint = 'ecommercetype';
  let method = "POST";
  if (id) {
    endpoint = `ecommercetype/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreEcommerceType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreEcommerceType = (result) => {
  return {
    type: Types.STORE_ECOMMERCE_TYPE,
    result,
  };
};
export const ResetStoreEcommerceType = () => {
  return (dispatch) => {

    dispatch(StoreEcommerceType(""));


  };
};






// connect_export_bill

export const ListConnectExportBillByCodeRequest = (body) => {
  var url = `connect-export-bill/code?code=${body.code}`;

  return (dispatch) => {

    return PostWeb(url, "", "post")
      .then(response => response.text())
      .then(result => {
        dispatch(ListConnectExportBillByCode(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListConnectExportBillByCode = (result) => {
  return {
    type: Types.LIST_CONNECT_EXPORT_BILL_BY_CODE,
    result,
  };
};

export const ListConnectExportBillByIdRequest = (body) => {
  var url = `connect-export-bill/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListConnectExportBillById(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListConnectExportBillById = (result) => {
  return {
    type: Types.LIST_CONNECT_EXPORT_BILL_BY_ID,
    result,
  };
};
export const ListConnectExportBillRequest = (body) => {
  let url = `connect-export-bill?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `connect-export-bill?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        // console.log("resultresultresult ",result);
        
        dispatch(ListConnectExportBill(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListConnectExportBill = (result) => {
  return {
    type: Types.LIST_CONNECT_EXPORT_BILL,
    result,
  };
};

export const StoreConnectExportBillRequest = (data, id = "") => {
  let endpoint = 'connect-export-bill';
  let method = "POST";
  if (id) {
    endpoint = `connect-export-bill/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreConnectExportBill(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const UpdateActiveConnectExportBillRequest = (data) => {
  let endpoint = 'connect-export-bill/update-active';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresultresultresult ",result);
        
        dispatch(StoreConnectExportBill(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


export const DeletedConnectExportBillRequest = (data) => {
  let endpoint = 'connect-export-bill/deleted';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreConnectExportBill(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreConnectExportBill = (result) => {
  return {
    type: Types.STORE_CONNECT_EXPORT_BILL,
    result,
  };
};
export const ResetStoreConnectExportBill = () => {
  return (dispatch) => {

    dispatch(StoreConnectExportBill(""));


  };
};
// district
export const ListDistrictsNoDispatchRequest = async (body) => {
  let url = `district?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}`
  if (body.s) {
    url = `district?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}`
  }
  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};


export const ListDistrictsByIdRequest = (body) => {
  var url = `district/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListDistricts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListDistrictsRequest = (body) => {
  let url = `district?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}`
  if (body.s) {
    url = `district?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListDistricts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListDistricts = (result) => {
  return {
    type: Types.LIST_DISTRICTS,
    result,
  };
};

export const StoreDistrictsRequest = (data, id = "") => {
  let endpoint = 'district';
  let method = "POST";
  if (id) {
    endpoint = `district/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreDistricts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreDistricts = (result) => {
  return {
    type: Types.STORE_DISTRICTS,
    result,
  };
};
export const ResetStoreDistricts = () => {
  return (dispatch) => {

    dispatch(StoreDistricts(""));


  };
};




// wars
export const ListWarsNoDispatchRequest = async (body) => {
  let url = `war?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}&districtid=${body.districtid}`
  if (body.s) {
    url = `war?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}&districtid=${body.districtid}`
  }
  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};

export const ListWarsByIdRequest = (body) => {
  var url = `war/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListWars(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListWarsRequest = (body) => {
  let url = `war?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}&districtid=${body.districtid}`
  if (body.s) {
    url = `war?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&provinceid=${body.provinceid}&districtid=${body.districtid}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListWars(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListWars = (result) => {
  return {
    type: Types.LIST_WARS,
    result,
  };
};

export const StoreWarsRequest = (data, id = "") => {
  let endpoint = 'war';
  let method = "POST";
  if (id) {
    endpoint = `war/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreWars(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreWars = (result) => {
  return {
    type: Types.STORE_WARS,
    result,
  };
};
export const ResetStoreWars = () => {
  return (dispatch) => {

    dispatch(StoreWars(""));


  };
};



// Role

export const ListRoleByIdRequest = (body) => {
  var url = `roles/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListRole(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListRoleRequest = (body) => {
  let url = `roles?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `roles?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListRole(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListRole = (result) => {
  return {
    type: Types.LIST_ROLE,
    result,
  };
};

export const StoreRoleRequest = (data, id = "") => {
  let endpoint = 'roles';
  let method = "POST";
  if (id) {
    endpoint = `roles/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreRole(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreRole = (result) => {
  return {
    type: Types.STORE_ROLE,
    result,
  };
};
export const ResetStoreRole = () => {
  return (dispatch) => {

    dispatch(StoreRole(""));


  };
};





// funct

export const ListFunctRequest = (body) => {
  let url = `funct?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `funct?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListFunct(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListFunct = (result) => {
  return {
    type: Types.LIST_FUNCT,
    result,
  };
};

export const StoreFunctRequest = (data, id = "") => {
  let endpoint = 'funct';
  let method = "POST";
  if (id) {
    endpoint = `funct/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreFunct(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreFunct = (result) => {
  return {
    type: Types.STORE_FUNCT,
    result,
  };
};
export const ResetStoreFunct = () => {
  return (dispatch) => {

    dispatch(StoreFunct(""));


  };
};



export const SearchFunctRequest = (body, data) => {
  let url = `funct/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchFunct(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchFunct = (result) => {
  return {
    type: Types.LIST_FUNCT,
    result,
  };
};

// menus

export const ListMenusRequest = (body) => {
  let url = `menus?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `menus?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListMenus(JSON.parse(result)));
        dispatch(SearchMenus(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListMenus = (result) => {
  return {
    type: Types.LIST_MENUS,
    result,
  };
};

export const StoreMenusRequest = (data, id = "") => {
  let endpoint = 'menus';
  let method = "POST";
  if (id) {
    endpoint = `menus/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreMenus(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreMenus = (result) => {
  return {
    type: Types.STORE_MENUS,
    result,
  };
};
export const ResetStoreMenus = () => {
  return (dispatch) => {

    dispatch(StoreMenus(""));


  };
};

export const SearchMenusRequest = (body, data) => {
  let url = `menus/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchMenus(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchMenus = (result) => {
  return {
    type: Types.LIST_MENUS_SEARCH,
    result,
  };
};

// route

export const ListRouteRequest = (body) => {
  let url = `routes?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `routes?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListRoute(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListRoute = (result) => {
  return {
    type: Types.LIST_ROUTE,
    result,
  };
};
// Store



export const DeleteStoreRequest = (body, id = '') => {
  let endpoint = `store/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreStore(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const duplicateStoreRequest = (body) => {
  var url = `store/duplicate/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreStore(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListStoreByIdRequest = (body) => {
  var url = `store/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListStore(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListStoreRequest = (body) => {
  let url = `store?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `store?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListStore(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchStoreRequest = (body, data) => {
  let url = `store/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(ListStore(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListStore = (result) => {
  return {
    type: Types.LIST_STORE,
    result,
  };
};


export const StoreStoreRequest = (data, id = "") => {
  let endpoint = 'store';
  let method = "POST";
  if (id) {
    endpoint = `store/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreStore(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreStore = (result) => {
  return {
    type: Types.STORE_STORE,
    result,
  };
};
export const ResetStoreStore = () => {
  return (dispatch) => {

    dispatch(StoreStore(""));


  };
};




// Tags

export const ListTagsRequest = (body) => {
  let url = `tags?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `tags?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListTags(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListTagsByIdRequest = (body) => {
  var url = `tags/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListTags(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListTags = (result) => {
  return {
    type: Types.LIST_TAGS,
    result,
  };
};

export const StoreTagsRequest = (data, id = "") => {
  let endpoint = 'tags';
  let method = "POST";
  if (id) {
    endpoint = `tags/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreTags(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreTags = (result) => {
  return {
    type: Types.STORE_TAGS,
    result,
  };
};
export const ResetStoreTags = () => {
  return (dispatch) => {

    dispatch(StoreTags(""));


  };
};




// Unit

export const ListUnitNoDispatchRequest =async (body) => {
  let url = `unit?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `unit?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const ListUnitRequest = (body) => {
  let url = `unit?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `unit?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListUnit(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListUnitByIdRequest = (body) => {
  var url = `unit/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListUnit(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListUnit = (result) => {
  return {
    type: Types.LIST_UNIT,
    result,
  };
};

export const StoreUnitNoDispatchRequest = async (data, id = "") => {
  let endpoint = 'unit';
  let method = "POST";
  if (id) {
    endpoint = `unit/${id}`
    method = "PUT";
  }
  try {
    const response = await PostWeb(endpoint, data, method);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const StoreUnitRequest = (data, id = "") => {
  let endpoint = 'unit';
  let method = "POST";
  if (id) {
    endpoint = `unit/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreUnit(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreUnit = (result) => {
  return {
    type: Types.STORE_UNIT,
    result,
  };
};
export const ResetStoreUnit = () => {
  return (dispatch) => {

    dispatch(StoreUnit(""));


  };
};

export const DeleteUnitRequest = (body, id = '') => {
  let endpoint = `unit/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreUnit(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const duplicateUnitRequest = (body) => {
  var url = `unit/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreUnit(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


// sortStamps

export const ListSortStampsRequest = (body) => {
  let url = `sort-stamps?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `sort-stamps?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListSortStamps(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListSortStampsByIdRequest = (body) => {
  var url = `sort-stamps/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListSortStamps(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListSortStamps = (result) => {
  return {
    type: Types.LIST_SORT_STAMPS,
    result,
  };
};
export const StoreMutilpleSortStampsRequest = (data, id = false) => {
  let endpoint = 'sort-stamps/store-mutilple';
  let method = "POST";
  if (id) {
    endpoint = `sort-stamps/update-mutilple`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreSortStamps(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreSortStampsRequest = (data, id = "") => {
  let endpoint = 'sort-stamps';
  let method = "POST";
  if (id) {
    endpoint = `sort-stamps/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreSortStamps(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreSortStamps = (result) => {
  return {
    type: Types.STORE_SORT_STAMPS,
    result,
  };
};
export const ResetStoreSortStamps = () => {
  return (dispatch) => {

    dispatch(StoreSortStamps(""));


  };
};

export const DeleteSortStampsRequest = (body, id = '') => {
  let endpoint = `sort-stamps/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreSortStamps(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const duplicateSortStampsRequest = (body) => {
  var url = `sort-stamps/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreSortStamps(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

// paper type

export const ListPaperTypeRequest = (body) => {
  let url = `paper-type?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `paper-type?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListPaperType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPaperTypeByIdRequest = (body) => {
  var url = `paper-type/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListPaperType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPaperType = (result) => {
  return {
    type: Types.LIST_PAPER_TYPE,
    result,
  };
};
export const StoreMutilplePaperTypeRequest = (data, id = false) => {
  let endpoint = 'paper-type/store-mutilple';
  let method = "POST";
  if (id) {
    endpoint = `paper-type/update-mutilple`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperTypeRequest = (data, id = "") => {
  let endpoint = 'paper-type';
  let method = "POST";
  if (id) {
    endpoint = `paper-type/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperType = (result) => {
  return {
    type: Types.STORE_PAPER_TYPE,
    result,
  };
};
export const ResetStorePaperType = () => {
  return (dispatch) => {

    dispatch(StorePaperType(""));


  };
};

export const DeletePaperTypeRequest = (body, id = '') => {
  let endpoint = `paper-type/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePaperType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const duplicatePaperTypeRequest = (body) => {
  var url = `paper-type/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


// paper price list

export const ListPaperPriceListRequest = (body) => {
  let url = `paper-price-list?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `paper-price-list?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListPaperPriceList(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPaperPriceListByIdRequest = (body) => {
  var url = `paper-price-list/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListPaperPriceList(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPaperPriceList = (result) => {
  return {
    type: Types.LIST_PAPER_PRICE_LIST,
    result,
  };
};
export const StoreMutilplePaperPriceListRequest = (data, id = false) => {
  let endpoint = 'paper-price-list/store-mutilple';
  let method = "POST";
  if (id) {
    endpoint = `paper-price-list/update-mutilple`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperPriceList(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperPriceListRequest = (data, id = "") => {
  let endpoint = 'paper-price-list';
  let method = "POST";
  if (id) {
    endpoint = `paper-price-list/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperPriceList(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperPriceList = (result) => {
  return {
    type: Types.STORE_PAPER_PRICE_LIST,
    result,
  };
};
export const ResetStorePaperPriceList = () => {
  return (dispatch) => {

    dispatch(StorePaperPriceList(""));


  };
};

export const DeletePaperPriceListRequest = (body, id = '') => {
  let endpoint = `paper-price-list/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePaperPriceList(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const duplicatePaperPriceListRequest = (body) => {
  var url = `paper-price-list/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperPriceList(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

// paper amount and discount

export const ListPaperAmountAndDiscountRequest = (body) => {
  let url = `paper-amount-and-discount?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `paper-amount-and-discount?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListPaperAmountAndDiscount(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPaperAmountAndDiscountByIdRequest = (body) => {
  var url = `paper-amount-and-discount/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListPaperAmountAndDiscount(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPaperAmountAndDiscount = (result) => {
  return {
    type: Types.LIST_PAPER_AMOUT_AND_DISCOUNT,
    result,
  };
};
export const StoreMutilplePaperAmountAndDiscountRequest = (data, id = false) => {
  let endpoint = 'paper-amount-and-discount/store-mutilple';
  let method = "POST";
  if (id) {
    endpoint = `paper-amount-and-discount/update-mutilple`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperAmountAndDiscount(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperAmountAndDiscountRequest = (data, id = "") => {
  let endpoint = 'paper-amount-and-discount';
  let method = "POST";
  if (id) {
    endpoint = `paper-amount-and-discount/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperAmountAndDiscount(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperAmountAndDiscount = (result) => {
  return {
    type: Types.STORE_PAPER_AMOUT_AND_DISCOUNT,
    result,
  };
};
export const ResetStorePaperAmountAndDiscount = () => {
  return (dispatch) => {

    dispatch(StorePaperAmountAndDiscount(""));


  };
};

export const DeletePaperAmountAndDiscountRequest = (body, id = '') => {
  let endpoint = `paper-amount-and-discount/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePaperAmountAndDiscount(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const duplicatePaperAmountAndDiscountRequest = (body) => {
  var url = `paper-amount-and-discount/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperAmountAndDiscount(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

// TradeMark

export const ListTradeMarkRequest = (body) => {
  let url = `trademark?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `trademark?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListTradeMark(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListTradeMark = (result) => {
  return {
    type: Types.LIST_TRADEMARK,
    result,
  };
};

export const StoreTradeMarkRequest = (data, id = "") => {
  let endpoint = 'trademark';
  let method = "POST";
  if (id) {
    endpoint = `trademark/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreTradeMark(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreTradeMark = (result) => {
  return {
    type: Types.STORE_TRADEMARK,
    result,
  };
};
export const ResetStoreTradeMark = () => {
  return (dispatch) => {

    dispatch(StoreTradeMark(""));


  };
};




// TypeProducts

export const ListTypeProductsRequest = (body) => {
  let url = `typeproduct?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `typeproduct?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListTypeProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListTypeProducts = (result) => {
  return {
    type: Types.LIST_TYPE_PRODUCTS,
    result,
  };
};

export const StoreTypeProductsRequest = (data, id = "") => {
  let endpoint = 'typeproduct';
  let method = "POST";
  if (id) {
    endpoint = `typeproduct/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreTypeProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreTypeProducts = (result) => {
  return {
    type: Types.STORE_TYPE_PRODUCTS,
    result,
  };
};
export const ResetStoreTypeProducts = () => {
  return (dispatch) => {

    dispatch(StoreTypeProducts(""));


  };
};




// users
export const ListUsersNodispatchRequest =async (body) => {
  let url = `user?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `user?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const ListUsersRequest = (body) => {
  let url = `user?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `user?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListUsers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListUsers = (result) => {
  return {
    type: Types.LIST_USERS,
    result,
  };
};

export const DeleteUsersRequest = (id = "") => {
  let endpoint = `user/delete/${id}`

  console.log('====================================');
  console.log("endpointendpointendpointendpoint:  ", endpoint, id);
  console.log('====================================');
  return (dispatch) => {

    return PostWeb(endpoint, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreUsers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreUsersRequest = (data, id = "") => {
  let endpoint = 'register';
  let method = "POST";
  if (id) {
    endpoint = `user/${id}`
    method = "PUT";
  }

  console.log('====================================');
  console.log("endpointendpointendpointendpoint:  ", endpoint, id);
  console.log('====================================');
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreUsers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreUsers = (result) => {
  return {
    type: Types.STORE_USERS,
    result,
  };
};
export const ResetStoreUsers = () => {
  return (dispatch) => {

    dispatch(StoreUsers(""));


  };
};


export const SearchUserRequest = (body, data) => {
  let url = `user/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  console.log('====================================');
  console.log(url);
  console.log('====================================');
  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log(JSON.parse(result));
        console.log('====================================');
        dispatch(SearchUser(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchUser = (result) => {
  return {
    type: Types.LIST_USERS,
    result,
  };
};

// Warehouse

export const ListWarehouseRequest = (body) => {
  let url = `warehouse?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `warehouse?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchWarehouseRequest = (body, data) => {
  let url = `warehouse/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(ListWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListWarehouse = (result) => {
  return {
    type: Types.LIST_WAREHOUSE,
    result,
  };
};

export const StoreWarehouseRequest = (data, id = "") => {
  let endpoint = 'warehouse';
  let method = "POST";
  if (id) {
    endpoint = `warehouse/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreWarehouse = (result) => {
  return {
    type: Types.STORE_WAREHOUSE,
    result,
  };
};
export const ResetStoreWarehouse = () => {
  return (dispatch) => {

    dispatch(StoreWarehouse(""));


  };
};





// WarrantyType

export const ListWarrantyTypeRequest = (body) => {
  let url = `warrantytype?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `warrantytype?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListWarrantyType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListWarrantyType = (result) => {
  return {
    type: Types.LIST_WARRANTY_TYPE,
    result,
  };
};

export const StoreWarrantyTypeRequest = (data, id = "") => {
  let endpoint = 'warrantytype';
  let method = "POST";
  if (id) {
    endpoint = `warrantytype/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreWarrantyType(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreWarrantyType = (result) => {
  return {
    type: Types.STORE_WARRANTY_TYPE,
    result,
  };
};
export const ResetStoreWarrantyType = () => {
  return (dispatch) => {

    dispatch(StoreWarrantyType(""));


  };
};




// OrganizationProduction

export const ListOrganizationProductionRequest = (body) => {
  let url = `orproduction?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `orproduction?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListOrganizationProduction(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListOrganizationProduction = (result) => {
  return {
    type: Types.LIST_ORGANIZATION_PRODUCTION,
    result,
  };
};

export const StoreOrganizationProductionRequest = (data, id = "") => {
  let endpoint = 'orproduction';
  let method = "POST";
  if (id) {
    endpoint = `orproduction/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrganizationProduction(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreOrganizationProduction = (result) => {
  return {
    type: Types.STORE_ORGANIZATION_PRODUCTION,
    result,
  };
};
export const ResetStoreOrganizationProduction = () => {
  return (dispatch) => {

    dispatch(StoreOrganizationProduction(""));


  };
};



// OrganizationProductionAddress

export const ListOrganizationProductionAddressRequest = (body) => {
  let url = `orproductionaddress?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `orproductionaddress?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListOrganizationProductionAddress(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListOrganizationProductionAddress = (result) => {
  return {
    type: Types.LIST_ORGANIZATION_PRODUCTION_ADDRESS,
    result,
  };
};

export const StoreOrganizationProductionAddressRequest = (data, id = "") => {
  let endpoint = 'orproductionaddress';
  let method = "POST";
  if (id) {
    endpoint = `orproductionaddress/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreOrganizationProductionAddress(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreOrganizationProductionAddress = (result) => {
  return {
    type: Types.STORE_ORGANIZATION_PRODUCTION_ADDRESS,
    result,
  };
};
export const ResetStoreOrganizationProductionAddress = () => {
  return (dispatch) => {

    dispatch(StoreOrganizationProductionAddress(""));


  };
};


// PaperTexture

export const ListPaperTextureRequest = (body) => {
  let url = `papertexture?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `papertexture?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListPaperTexture(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListPaperTexture = (result) => {
  return {
    type: Types.LIST_PAPER_TEXTURE,
    result,
  };
};

export const StorePaperTextureRequest = (data, id = "") => {
  let endpoint = 'papertexture';
  let method = "POST";
  if (id) {
    endpoint = `papertexture/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePaperTexture(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePaperTexture = (result) => {
  return {
    type: Types.STORE_PAPER_TEXTURE,
    result,
  };
};
export const ResetStorePaperTexture = () => {
  return (dispatch) => {

    dispatch(StorePaperTexture(""));


  };
};




// InfoPackage

export const InfoPackageActiveRequest = (body) => {
  let url = `infopackage/getactive`

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(InfoPackageActive(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const InfoPackageActive = (result) => {
  return {
    type: Types.INFOPACKAGE_ACTIVE,
    result,
  };
};

export const ListInfoPackageRequest = (body) => {
  let url = `infopackage?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `infopackage?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListInfoPackage(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListInfoPackage = (result) => {
  return {
    type: Types.LIST_INFOPACKAGE,
    result,
  };
};

export const StoreInfoPackageRequest = (data, id = "") => {
  let endpoint = 'infopackage';
  let method = "POST";
  if (id) {
    endpoint = `infopackage/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreInfoPackage(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreInfoPackage = (result) => {
  return {
    type: Types.STORE_INFOPACKAGE,
    result,
  };
};
export const ResetStoreInfoPackage = () => {
  return (dispatch) => {

    dispatch(StoreInfoPackage(""));


  };
};

// ==============================================


// Settings

export const SettingsShowByCodeRequest = (body) => {
  let url = `settings/showcode/${body.code}`

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(SettingsShowByCode(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SettingsShowByCode = (result) => {
  return {
    type: Types.SETTINGS_BY_CODE,
    result,
  };
};
export const SettingsActiveRequest = (body) => {
  let url = `settings/getactive`

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(SettingsActive(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SettingsActive = (result) => {
  return {
    type: Types.SETTINGS_ACTIVE,
    result,
  };
};

export const ListSettingsRequest = (body) => {
  let url = `settings?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `settings?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListSettings(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListSettings = (result) => {
  return {
    type: Types.LIST_SETTINGS,
    result,
  };
};

export const StoreSettingsRequest = (data, id = "") => {
  let endpoint = 'settings';
  let method = "POST";
  if (id) {
    endpoint = `settings/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        console.log('====================================');
        console.log(result);
        console.log('====================================');
        dispatch(StoreSettings(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreSettings = (result) => {
  return {
    type: Types.STORE_SETTINGS,
    result,
  };
};
export const ResetStoreSettings = () => {
  return (dispatch) => {

    dispatch(StoreSettings(""));


  };
};

// ==============================================


// Banners

export const BannersActiveRequest = (body) => {
  let url = `banners/getactive`

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(BannersActive(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const BannersActive = (result) => {
  return {
    type: Types.BANNERS_ACTIVE,
    result,
  };
};

export const ListBannersRequest = (body) => {
  let url = `banners?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `banners?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListBanners(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListBanners = (result) => {
  return {
    type: Types.LIST_BANNERS,
    result,
  };
};

export const StoreBannersRequest = (data, id = "") => {
  let endpoint = 'banners';
  let method = "POST";
  if (id) {
    endpoint = `banners/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreBanners(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreBanners = (result) => {
  return {
    type: Types.STORE_BANNERS,
    result,
  };
};
export const ResetStoreBanners = () => {
  return (dispatch) => {

    dispatch(StoreBanners(""));


  };
};
export const BannerByIdRequest = (body) => {
  // console.log("BannerByIdRequestBannerByIdRequest bodybodybodybody ",body);
  var url = `banners/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        // console.log("BannerByIdRequest resultresultresultresultresult ",result);

        dispatch(ListBanners(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchBannersRequest = (body, data) => {
  let url = `banners/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(ListBanners(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};



// ////////////////////////
export const StoreAttributeMultipleRequest = (data) => {
  let endpoint = 'attributepr/multiple';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreAttributeProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListAttributeProductsRequest = (body) => {
  let url = `attributepr?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `attributepr?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListAttributeProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListAttributeProductsByIdRequest = (body) => {
  var url = `attributepr/${body?.id}`;

  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        dispatch(ListAttributeProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListAttributeProducts = (result) => {
  return {
    type: Types.LIST_ATTRIBUTE_PRODUCTS,
    result,
  };
};

export const StoreAttributeProductsRequest = (data, id = "") => {
  let endpoint = 'attributepr';
  let method = "POST";
  if (id) {
    endpoint = `attributepr/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreAttributeProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreAttributeProducts = (result) => {
  return {
    type: Types.STORE_ATTRIBUTE_PRODUCTS,
    result,
  };
};
export const ResetStoreAttributeProducts = () => {
  return (dispatch) => {

    dispatch(StoreAttributeProducts(""));


  };
};
export const SetAttributeProducts = (data) => {

  return (dispatch) => {

    dispatch(ListAttributeProducts({ data: { data: data }, code: 200 }));


  };
};
// ========================================


//supplier
// ==============================================

export const ListSupplierRequest = (body) => {
  let url = `supplier?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `supplier?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListSupplier(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SupplierByIdRequest = (body) => {
  
  var url = `supplier/${body?.id}`;
 
  
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListSupplier(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListSupplier = (result) => {
  return {
    type: Types.LIST_SUPPLIER,
    result,
  };
};

export const StoreSupplierRequest = (data, id = "") => {
  let endpoint = 'supplier';
  let method = "POST";
  if (id) {
    endpoint = `supplier/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreSupplier(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreSupplier = (result) => {
  return {
    type: Types.STORE_SUPPLIER,
    result,
  };
};
export const ResetStoreSupplier = () => {
  return (dispatch) => {

    dispatch(StoreSupplier(""));


  };
};
export const SetSupplier = (data) => {

  return (dispatch) => {

    dispatch(ListSupplier({ data: { data: data }, code: 200 }));


  };
};
export const SearchSupplierNoDispatchRequest =async (body, data) => {
  let url = `supplier/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SearchSupplierRequest = (body, data) => {
  let url = `supplier/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchSupplier(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchSupplier = (result) => {
  return {
    type: Types.LIST_SUPPLIER,
    result,
  };
};

// ========================================





//Customers
// ==============================================

export const ListCustomersRequest = (body) => {
  let url = `customers?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `customers?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListCustomers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const CustomerByIdNoDispatchRequest =async (body) => {
  var url = `customers/${body?.id}`;

  // let url = `accounting-account?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&key=${body.key}`
  // console.log(url);

  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
 
};
export const CustomerByIdRequest = (body) => {
  var url = `customers/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListCustomers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListCustomers = (result) => {
  return {
    type: Types.LIST_CUSTOMERS,
    result,
  };
};

export const DeleteCustomersRequest = (id = "") => {
  let endpoint = `customers/delete/${id}`

  console.log('====================================');
  console.log("endpointendpointendpointendpoint:  ", endpoint, id);
  console.log('====================================');
  return (dispatch) => {

    return PostWeb(endpoint, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreCustomers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};


export const StoreCustomersNoDisPatchRequest = async (data, id = "") => {
  let endpoint = 'customers';
  let method = "POST";
  if (id) {
    endpoint = `customers/${id}`
    method = "PUT";
  }
  try {
    const response = await PostWeb(endpoint, data, method);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const StoreCustomersRequest = (data, id = "") => {
  let endpoint = 'customers';
  let method = "POST";
  if (id) {
    endpoint = `customers/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreCustomers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreCustomers = (result) => {
  return {
    type: Types.STORE_CUSTOMERS,
    result,
  };
};
export const ResetStoreCustomers = () => {
  return (dispatch) => {

    dispatch(StoreCustomers(""));


  };
};
export const SetCustomers = (data) => {

  return (dispatch) => {

    dispatch(ListCustomers({ data: { data: data }, code: 200 }));


  };
};

export const SearchCustomersNoDispatchRequest = async(body, data) => {
  let url = `customers/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SearchCustomersRequest = (body, data) => {
  let url = `customers/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        dispatch(SearchCustomers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchCustomers = (result) => {
  return {
    type: Types.LIST_CUSTOMERS,
    result,
  };
};

// ========================================




//Contact
// ==============================================

export const ListContactsRequest = (body) => {
  let url = `contact?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `contact?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListContacts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ContactByIdRequest = (body) => {
  var url = `contact/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListContacts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListContacts = (result) => {
  return {
    type: Types.LIST_CONTACT,
    result,
  };
};

export const StoreContactsRequest = (data, id = "") => {
  let endpoint = 'contact';
  let method = "POST";
  if (id) {
    endpoint = `contact/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreContacts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreContacts = (result) => {
  return {
    type: Types.STORE_CONTACT,
    result,
  };
};
export const ResetStoreContacts = () => {
  return (dispatch) => {

    dispatch(StoreContacts(""));


  };
};
export const SetContacts = (data) => {

  return (dispatch) => {

    dispatch(ListContacts({ data: { data: data }, code: 200 }));


  };
};

export const SearchContactsRequest = (body, data) => {
  let url = `contact/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchContacts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchContacts = (result) => {
  return {
    type: Types.LIST_CONTACT,
    result,
  };
};

// ========================================
//Vouchers
// ==============================================

export const CheckVouchersRequest = (body) => {
  let url = `vouchers/checkvoucher`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {


        dispatch(CheckVouchers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ResetCheckVouchersRequest = (body) => {

  return (dispatch) => {

    dispatch(CheckVouchers({ code: 401 }));
  };
};
export const CheckVouchers = (result) => {
  return {
    type: Types.CHECK_VOUCHERS,
    result,
  };
};
export const ListVouchersRequest = (body) => {
  let url = `vouchers?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `vouchers?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListVouchers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const VoucherByIdRequest = (body) => {
  var url = `vouchers/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListVouchers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListVouchers = (result) => {
  return {
    type: Types.LIST_VOUCHERS,
    result,
  };
};

export const StoreVouchersRequest = (data, id = "") => {
  let endpoint = 'vouchers';
  let method = "POST";
  if (id) {
    endpoint = `vouchers/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreVouchers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreVouchers = (result) => {
  return {
    type: Types.STORE_VOUCHERS,
    result,
  };
};
export const ResetStoreVouchers = () => {
  return (dispatch) => {

    dispatch(StoreVouchers(""));


  };
};
export const ResetListVouchers = () => {
  return (dispatch) => {

    dispatch(ListVouchers(""));


  };
};
export const SetVouchers = (data) => {

  return (dispatch) => {

    dispatch(ListVouchers({ data: { data: data }, code: 200 }));


  };
};

export const SearchVouchersRequest = (body, data) => {
  let url = `vouchers/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("propspropspropspropsprops", result);
        dispatch(SearchVouchers(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchVouchers = (result) => {
  return {
    type: Types.LIST_VOUCHERS,
    result,
  };
};




export const getCondisCustomerRequest = (body) => {
  let url = `vouchercondis`

  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(getCondisCustomer(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const getCondisCustomer = (result) => {
  return {
    type: Types.GET_CONDIS_CUS,
    result,
  };
};

// ========================================


// voucher group
export const ListVouchersGroupRequest = (body) => {
  let url = `vouchersgroup?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `vouchersgroup?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListVouchersGroup(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const VoucherGroupByIdRequest = (body) => {
  var url = `vouchersgroup/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListVouchersGroup(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListVouchersGroup = (result) => {
  return {
    type: Types.LIST_VOUCHERS_GROUP,
    result,
  };
};

export const StoreVouchersGroupRequest = (data, id = "") => {
  let endpoint = 'vouchersgroup';
  let method = "POST";
  if (id) {
    endpoint = `vouchersgroup/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreVouchersGroup(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreVouchersGroup = (result) => {
  return {
    type: Types.STORE_VOUCHERS_GROUP,
    result,
  };
};
export const ResetStoreVouchersGroup = () => {
  return (dispatch) => {

    dispatch(StoreVouchersGroup(""));


  };
};
export const SetVouchersGroup = (data) => {

  return (dispatch) => {

    dispatch(ListVouchersGroup({ data: { data: data }, code: 200 }));


  };
};

export const SearchVouchersGroupRequest = (body, data) => {
  let url = `vouchersgroup/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchVouchersGroup(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchVouchersGroup = (result) => {
  return {
    type: Types.LIST_VOUCHERS_GROUP,
    result,
  };
};

//collections
// ==============================================
export const ListCollectionsProductsRequest = (body) => {
  let url = `collectionsproducts?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&collection_id=${body.collection_id}`
  if (body.s) {
    url = `collectionsproducts?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&collection_id=${body.collection_id}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListCollectionsProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const ListCollectionsProducts = (result) => {
  return {
    type: Types.LIST_COLLECTIONS_PRODUCTS,
    result,
  };
};

export const ListCollectionsRequest = (body) => {
  let url = `collections?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `collections?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const CollectionByIdRequest = (body) => {
  var url = `collections/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListCollections = (result) => {
  return {
    type: Types.LIST_COLLECTIONS,
    result,
  };
};
export const DeleteCollectionsRequest = (data, id = "") => {
  let endpoint = 'collections/delete/' + id;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const UpdateStatusCollectionsRequest = (data, id = "") => {
  let endpoint = 'collections/update-status/' + id;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreCollectionsRequest = (data, id = "") => {
  let endpoint = 'collections';
  let method = "POST";
  if (id) {
    endpoint = `collections/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreCollectionsProductsRequest = (data) => {
  let endpoint = 'collections/updatecollectionproducts';
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreCollections = (result) => {
  return {
    type: Types.STORE_COLLECTIONS,
    result,
  };
};
export const ResetStoreCollections = () => {
  return (dispatch) => {

    dispatch(StoreCollections(""));


  };
};
export const SetCollections = (data) => {

  return (dispatch) => {

    dispatch(ListCollections({ data: { data: data }, code: 200 }));


  };
};

export const SearchCollectionsRequest = (body, data) => {
  let url = `collections/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchCollections(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchCollections = (result) => {
  return {
    type: Types.LIST_COLLECTIONS,
    result,
  };
};


export const SearchCollectionsProductsRequest = (body, data) => {
  let url = `collections/searchproducts?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchCollectionsProducts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const SearchCollectionsProducts = (result) => {
  return {
    type: Types.SEARCH_COLLECTIONS_PRODUCTS,
    result,
  };
};
export const ResetSearchCollectionsProducts = () => {
  return (dispatch) => {

    dispatch(SearchCollectionsProducts(""));


  };
};
// ========================================


//Reports
// ==============================================

export const ReportRequest = (body, data) => {
  var url = `reports?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`;
  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        // console.log("resultresult",result);
        dispatch(Reports(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const Reports = (result) => {
  return {
    type: Types.REPORTS,
    result,
  };
};

//Posts
// ==============================================

export const duplicatePostRequest = (body) => {
  var url = `posts/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StorePosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPostsRequest = (body) => {
  let url = `posts?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `posts?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListPosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const PostByIdRequest = (body) => {
  var url = `posts/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListPosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPosts = (result) => {
  return {
    type: Types.LIST_POSTS,
    result,
  };
};
export const DeletePostsRequest = (body, id = '') => {
  let endpoint = `posts/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StorePostsRequest = (data, id = "") => {
  let endpoint = 'posts';
  let method = "POST";
  if (id) {
    endpoint = `posts/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePosts = (result) => {
  return {
    type: Types.STORE_POSTS,
    result,
  };
};
export const ResetStorePosts = () => {
  return (dispatch) => {

    dispatch(StorePosts(""));


  };
};
export const SetPosts = (data) => {

  return (dispatch) => {

    dispatch(ListPosts({ data: { data: data }, code: 200 }));


  };
};

export const SearchPostsRequest = (body, data) => {
  let url = `posts/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchPosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchPosts = (result) => {
  return {
    type: Types.LIST_POSTS,
    result,
  };
};
export const HiddenPostRequest = (body, id = '') => {
  let endpoint = `posts/hidden/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePosts(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
// ========================================




//NetworkHome
// ==============================================

export const duplicateNetworkHomeRequest = (body) => {
  var url = `network-home/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreNetworkHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListNetworkHomeRequest = (body) => {
  let url = `network-home?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `network-home?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListNetworkHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const NetworkHomeByIdRequest = (body) => {
  var url = `network-home/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListNetworkHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListNetworkHome = (result) => {
  return {
    type: Types.LIST_NETWORK_HOME,
    result,
  };
};
export const DeleteNetworkHomeRequest = (body, id = '') => {
  let endpoint = `network-home/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreNetworkHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreNetworkHomeRequest = (data, id = "") => {
  let endpoint = 'network-home';
  let method = "POST";
  if (id) {
    endpoint = `network-home/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreNetworkHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreNetworkHome = (result) => {
  return {
    type: Types.STORE_NETWORK_HOME,
    result,
  };
};
export const ResetStoreNetworkHome = () => {
  return (dispatch) => {

    dispatch(StoreNetworkHome(""));


  };
};
export const SetNetworkHome = (data) => {

  return (dispatch) => {

    dispatch(ListNetworkHome({ data: { data: data }, code: 200 }));


  };
};

export const SearchNetworkHomeRequest = (body, data) => {
  let url = `network-home/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchNetworkHome(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchNetworkHome = (result) => {
  return {
    type: Types.LIST_NETWORK_HOME,
    result,
  };
};

// ========================================



//NetworkHomeOperator
// ==============================================

export const duplicateNetworkHomeOperatorRequest = (body) => {
  var url = `network-home-operator/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreNetworkHomeOperator(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListNetworkHomeOperatorRequest = (body) => {
  let url = `network-home-operator?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `network-home-operator?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListNetworkHomeOperator(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const NetworkHomeOperatorByIdRequest = (body) => {
  var url = `network-home-operator/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListNetworkHomeOperator(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListNetworkHomeOperator = (result) => {
  return {
    type: Types.LIST_NETWORK_HOME_OPERATOR,
    result,
  };
};
export const DeleteNetworkHomeOperatorRequest = (body, id = '') => {
  let endpoint = `network-home-operator/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreNetworkHomeOperator(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreNetworkHomeOperatorRequest = (data, id = "") => {
  let endpoint = 'network-home-operator';
  let method = "POST";
  if (id) {
    endpoint = `network-home-operator/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreNetworkHomeOperator(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreNetworkHomeOperator = (result) => {
  return {
    type: Types.STORE_NETWORK_HOME_OPERATOR,
    result,
  };
};
export const ResetStoreNetworkHomeOperator = () => {
  return (dispatch) => {

    dispatch(StoreNetworkHomeOperator(""));


  };
};
export const SetNetworkHomeOperator = (data) => {

  return (dispatch) => {

    dispatch(ListNetworkHomeOperator({ data: { data: data }, code: 200 }));


  };
};

export const SearchNetworkHomeOperatorRequest = (body, data) => {
  let url = `network-home-operator/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresult", result);
        dispatch(SearchNetworkHomeOperator(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchNetworkHomeOperator = (result) => {
  return {
    type: Types.LIST_NETWORK_HOME_OPERATOR,
    result,
  };
};

// ========================================

// products_warehouse
// ==============================================

export const duplicateProductsWarehouseRequest = (body) => {
  var url = `products_warehosue/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StoreProductsWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListProductsWarehouseNoDispatchRequest = async (body) => {
  let url = `products_warehosue?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `products_warehosue?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  try {
    const response = await PostWeb(url, null, "GET");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const ListProductsWarehouseRequest = (body) => {
  let url = `products_warehosue?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `products_warehosue?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListProductsWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ProductsWarehouseByIdRequest = (body) => {
  var url = `products_warehosue/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListProductsWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListProductsWarehouse = (result) => {
  return {
    type: Types.LIST_PRODUCTS_WAREHOUSE,
    result,
  };
};
export const DeleteProductsWarehouseRequest = (body, id = '') => {
  let endpoint = `products_warehosue/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StoreProductsWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const StoreProductsWarehouseRequest = (data, id = "") => {
  let endpoint = 'products_warehosue';
  let method = "POST";
  if (id) {
    endpoint = `products_warehosue/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresult", result);
        dispatch(StoreProductsWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreProductsWarehouse = (result) => {
  return {
    type: Types.STORE_PRODUCTS_WAREHOUSE,
    result,
  };
};
export const ResetStoreProductsWarehouse = () => {
  return (dispatch) => {

    dispatch(StoreProductsWarehouse(""));


  };
};
export const SetProductsWarehouse = (data) => {

  return (dispatch) => {

    dispatch(ListProductsWarehouse({ data: { data: data }, code: 200 }));


  };
};
export const SearchProductsWarehouseNoDispatchRequest = async (body, data) => {
  let url = `products_warehosue/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&is_asset=${body.is_asset}`

  try {
    const response = await PostWeb(url, data, "POST");
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;  // Nếu bạn muốn truyền lỗi này ra ngoài để xử lý tiếp
  }
};
export const SearchProductsWarehouseRequest = (body, data) => {
  let url = `products_warehosue/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchProductsWarehouse(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchProductsWarehouse = (result) => {
  return {
    type: Types.LIST_PRODUCTS_WAREHOUSE,
    result,
  };
};

// ========================================


//Pages
// ==============================================

export const DeletePagesRequest = (body, id = '') => {
  let endpoint = `pages/delete/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const getViewDataColectionRequest = (body) => {

  var url = `pages/getviewCollection`;
  return (dispatch) => {

    return PostWeb(url, body, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(getViewDataColection(result));

      }).catch(error1 => console.log('error', error1));

  };
};
export const resetViewDataColectionRequest = () => {

  return (dispatch) => {

    return dispatch(getViewDataColection(""));

  };
};
export const getViewDataColection = (result) => {
  return {
    type: Types.GET_VIEW_COLECTION,
    result,
  };
};
export const duplicatePagesRequest = (body) => {
  var url = `pages/duplicate/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(StorePages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPagesRequest = (body) => {
  let url = `pages?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `pages?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListPages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const PageByIdRequest = (body) => {
  var url = `pages/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {
        console.log("resultresultresult", result);
        dispatch(ListPages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListPages = (result) => {
  return {
    type: Types.LIST_PAGES,
    result,
  };
};

export const StorePagesRequest = (data, id = "") => {
  let endpoint = 'pages';
  let method = "POST";
  if (id) {
    endpoint = `pages/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StorePages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StorePages = (result) => {
  return {
    type: Types.STORE_PAGES,
    result,
  };
};
export const ResetStorePages = () => {
  return (dispatch) => {

    dispatch(StorePages(""));


  };
};
export const SetPages = (data) => {

  return (dispatch) => {

    dispatch(ListPages({ data: { data: data }, code: 200 }));


  };
};

export const SearchPagesRequest = (body, data) => {
  let url = `pages/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchPages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchPages = (result) => {
  return {
    type: Types.LIST_PAGES,
    result,
  };
};

export const PagesGetViewRequest = (body) => {
  let url = `pages/getview`

  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(PagesGetView(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const PagesGetView = (result) => {
  return {
    type: Types.PAGES_GETVIEW,
    result,
  };
};

export const PagesGetViewComponentRequest = (body) => {
  let url = `pages/getviewComponent`

  return (dispatch) => {

    return PostWeb(url, "", "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(PagesGetViewComponent(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const PagesGetViewComponent = (result) => {
  return {
    type: Types.PAGES_GETVIEW_COMPONENT,
    result,
  };
};

export const HiddenPageRequest = (body, id = '') => {
  let endpoint = `pages/hidden/${id}`;
  let method = "POST";

  return (dispatch) => {

    return PostWeb(endpoint, body, method)
      .then(response => response.text())
      .then(result => {
        dispatch(StorePages(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
// ========================================

//Feedback
// ==============================================

export const ListFeedbackRequest = (body) => {
  let url = `feedback?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `feedback?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {


        dispatch(ListFeedback(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const FeedbackByIdRequest = (body) => {
  var url = `feedback/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListFeedback(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListFeedback = (result) => {
  return {
    type: Types.LIST_FEEDBACK,
    result,
  };
};

export const StoreFeedbackRequest = (data, id = "") => {
  let endpoint = 'feedback';
  let method = "POST";
  if (id) {
    endpoint = `feedback/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreFeedback(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreFeedback = (result) => {
  return {
    type: Types.STORE_FEEDBACK,
    result,
  };
};
export const ResetStoreFeedback = () => {
  return (dispatch) => {

    dispatch(StoreFeedback(""));


  };
};
export const SetFeedback = (data) => {

  return (dispatch) => {

    dispatch(ListFeedback({ data: { data: data }, code: 200 }));


  };
};

export const SearchFeedbackRequest = (body, data) => {
  let url = `feedback/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchFeedback(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchFeedback = (result) => {
  return {
    type: Types.LIST_FEEDBACK,
    result,
  };
};

// ========================================

// ==============================================
//Inventory
export const ListInventoryRequest = (body) => {
  let url = `inventory?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `inventory?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListInventory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const InventoryByIdRequest = (body) => {
  var url = `inventory/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListInventory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListInventory = (result) => {
  return {
    type: Types.LIST_INVENTORY_IN,
    result,
  };
};

export const StoreInventoryRequest = (data, id = "") => {
  let endpoint = 'inventory';
  let method = "POST";
  if (id) {
    endpoint = `inventory/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreInventory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreInventory = (result) => {
  return {
    type: Types.STORE_INVENTORY_IN,
    result,
  };
};
export const ResetStoreInventory = () => {
  return (dispatch) => {

    dispatch(StoreInventory(""));


  };
};
export const SetInventory = (data) => {

  return (dispatch) => {

    dispatch(ListInventory({ data: { data: data }, code: 200 }));


  };
};

export const SearchInventoryRequest = (body, data) => {
  let url = `inventory/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(ListInventory(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchInventory = (result) => {
  return {
    type: Types.LIST_INVENTORY_OUT,
    result,
  };
};

// ========================================



// ==============================================
//Inventorycheck
export const ListInventoryCheckRequest = (body) => {
  let url = `inventorycheck?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`
  if (body.s) {
    url = `inventorycheck?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}&s=${body.s}`
  }
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListInventoryCheck(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const InventoryCheckByIdRequest = (body) => {
  var url = `inventorycheck/${body?.id}`;
  return (dispatch) => {

    return PostWeb(url, "", "GET")
      .then(response => response.text())
      .then(result => {

        dispatch(ListInventoryCheck(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const ListInventoryCheck = (result) => {
  return {
    type: Types.LIST_INVENTORY_OUT,
    result,
  };
};

export const StoreInventoryCheckRequest = (data, id = "") => {
  let endpoint = 'inventorycheck';
  let method = "POST";
  if (id) {
    endpoint = `inventorycheck/${id}`
    method = "PUT";
  }
  return (dispatch) => {

    return PostWeb(endpoint, data, method)
      .then(response => response.text())
      .then(result => {

        dispatch(StoreInventoryCheck(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const StoreInventoryCheck = (result) => {
  return {
    type: Types.STORE_INVENTORY_OUT,
    result,
  };
};
export const ResetStoreInventoryCheck = () => {
  return (dispatch) => {

    dispatch(StoreInventoryCheck(""));


  };
};
export const SetInventoryCheck = (data) => {

  return (dispatch) => {

    dispatch(ListInventoryCheck({ data: { data: data }, code: 200 }));


  };
};

export const SearchInventoryCheckRequest = (body, data) => {
  let url = `inventorycheck/filter?limit=${body.limit}&page=${body.page}&column=${body.column}&sort=${body.sort}`

  return (dispatch) => {

    return PostWeb(url, data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(SearchInventoryCheck(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};
export const SearchInventoryCheck = (result) => {
  return {
    type: Types.LIST_INVENTORY_OUT,
    result,
  };
};

// ========================================
export const UploadFileLaRequest = (data, folder, type, key = null) => {

  return (dispatch) => {


    return UploadFile(data, folder, type, key)
      .then(response => response.text())
      .then(result => {

        dispatch(UploadFile_(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const UploadFile_ = (result) => {
  return {
    type: Types.UPLOAD_FILE_LA,
    result,
  };
};
export const ResetUploadFileLa = () => {
  return (dispatch) => {

    dispatch(UploadFile_(""));


  };
};

export const DeleteFileLaRequest = (data) => {

  return (dispatch) => {

    return PostWeb("deletefiles", data, "POST")
      .then(response => response.text())
      .then(result => {

        dispatch(DeleteFile_(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const DeleteFile_ = (result) => {
  return {
    type: Types.DELETE_FILE_LA,
    result,
  };
};

export const ResetDeleteFile_ = (result) => {
  return (dispatch) => {
    dispatch(DeleteFile_(""));
  };
};