import React, { useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Empty, Form, Input, message, Popover, Select, Skeleton, Spin, Table, Tooltip, TreeSelect } from 'antd';
import "./style.css"
import { CarryoverAccountantRequest, CloseAccountantRequest, DeleteAccountantRequest, GroupAccountantRequest, ListAccountantRequest, ListAccountingAccountRequest, StoreAccountantRequest, StoreAccountingAccountRequest } from '../../../actions/indexWeb';
import { META } from '../../../utils/constant';
import { DATA_GROUP_ACCOUNT, DATA_KEY_CODE_ACCOUNT, DATA_PRECIOUS } from '../../../constants/DefaultData';
import {
  LoadingOutlined, SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from "../../../constants/Links";

import { formatDate, formatDate_fm, formatDateVN, formatNumber, getParamsUrl } from '../../../service';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const { RangePicker } = DatePicker;

const Index = () => {

  const [data, setData] = useState(null);
  const [dataTotal, setDataTotal] = useState(null);
  const [isClose, setIsClose] = useState(false);
  const [meta, setMeta] = useState({ ...META });
  const [isloading, setIsloading] = useState(false);
  const [isLoadingSearch, SetisLoadingSeach] = useState(false);

  const [isEdit, setIsEdit] = useState(-1);
  const [listAccount, setListAccount] = useState([]);
  const [record, setRecord] = useState(null);
  const [listAccountant, setListAccountant] = useState([]);
  const formRef = useRef(null);
  const newRowRef = useRef(null);
  const formSearchRef = useRef(null);
  const history = useHistory();

  const getListAccount = async () => {
    const response = await ListAccountingAccountRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "code", sort: "ASC"
    });
    if (response.code == 200) {
      if (response.data) {
        console.log(response.data.data);
        if (response.data.data) {

          setListAccount(response.data.data);
        }
      }
    }


  }

  const getListGroupAccountant = async () => {

    const urlParams = getParamsUrl();
    const filteredvalues = Object.fromEntries(
      Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
    );

    if (filteredvalues.isAll) {
      filteredvalues.isAll = parseInt(filteredvalues.isAll)
    }
    let quarterMoment = null;
    if (filteredvalues.precious) {

      const quarter = filteredvalues.precious;
      const year = filteredvalues.year ? filteredvalues.year : moment().year();
      quarterMoment = moment().year(year).quarter(quarter);

    } else {
      // Lấy tháng hiện tại
      const currentMonth = moment().month() + 1; // moment().month() trả về giá trị từ 0-11, cần +1 để từ 1-12
      let quarter = 1;

      const year = moment().year();
      // Tính toán quý hiện tại
      if (currentMonth <= 3) {
        quarter = 1; // Quý 1
      } else if (currentMonth <= 6) {
        quarter = 2;
      } else if (currentMonth <= 9) {
        quarter = 3;
      } else {
        quarter = 4;
      }
      filteredvalues.precious = quarter;
      filteredvalues.year = year;
      quarterMoment = moment().year(year).quarter(quarter);


    }
    const searchParams = new URLSearchParams(filteredvalues).toString();
    history.push(`${LINK.WEB_ACCOUNTANT_BALANCE}?${searchParams}`);

    const response = await GroupAccountantRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "date_cre_deb", sort: "ASC",is_balance:1
    }, { data: { ...filteredvalues } });
    if (response.code == 200) {

      console.log("responseresponseresponse ", response);

      if (response.data) {

        setData([...response.data]);
      }
      if (response.total) {

        setDataTotal(response.total);
      }
      setIsClose(response?.isClose);
    }
    if (filteredvalues.month) {
      filteredvalues.month = moment.utc(filteredvalues.month, "YYYY-MM");
    }

    if (quarterMoment) {
      filteredvalues.precious = quarterMoment;
    }
    if(formSearchRef.current){
      formSearchRef.current.setFieldsValue(filteredvalues)
    }
   

  }
  useEffect(() => {
    getListAccount();
    getListGroupAccountant();
    return () => {

    };
  }, []);

  const onSearch = async (values) => {
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" && value !== null })
    );

    if (filteredvalues.month) {
      filteredvalues.month = filteredvalues.month.format("YYYY-MM")
    }
    if (filteredvalues.precious) {
      const quarter = moment(filteredvalues.precious).quarter();
      const year = moment(filteredvalues.precious).year();
      filteredvalues.precious = quarter;
      filteredvalues.year = year;

    }
    if (!values.precious || values.month) {
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
    }
    SetisLoadingSeach(true);
    const response = await GroupAccountantRequest({
      limit: meta.per_page * 100,
      page: meta.current_page, column: "date_cre_deb", sort: "ASC"
    }, { data: { ...filteredvalues } });

    SetisLoadingSeach(false);

    if (response.code == 200) {

      console.log("responseresponseresponse ", response);

      if (response.data) {

        setData([...response.data]);
      }
      if (response.total) {

        setDataTotal(response.total);
      }
      setIsClose(response?.isClose);
    }

    const searchParams = new URLSearchParams(filteredvalues).toString();

    history.push(`${LINK.WEB_ACCOUNTANT_BALANCE}?${searchParams}`);


  }

  const resetFormSearch = () => {
    if (formSearchRef.current) {
      formSearchRef.current.resetFields();
      formSearchRef.current.submit();
    }
  }
  const closeBalance = async () => {
    const values = formSearchRef.current.getFieldsValue();
    const filteredvalues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
    );

    if (filteredvalues.month) {
      filteredvalues.month = filteredvalues.month.format("YYYY-MM")
    }
    if (filteredvalues.precious) {
      const quarter = moment(filteredvalues.precious).quarter();
      const year = moment(filteredvalues.precious).year();
      filteredvalues.precious = quarter;
      filteredvalues.year = year;

    }
    if (!values.precious || values.month) {
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
    }
    SetisLoadingSeach(true);
    const response = await CloseAccountantRequest(filteredvalues);

    SetisLoadingSeach(false);

    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }

      getListGroupAccountant();

    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const carryover = async (code, type) => {
    const values = formSearchRef.current.getFieldsValue();
    if (values.month) {
      values.month = values.month.format("YYYY-MM")
    }
    if (values.precious) {
      const quarter = moment(values.precious).quarter();
      const year = moment(values.precious).year();
      values.precious = quarter;
      values.year = year;

    }

    if (!values.precious || values.month) {
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
    }
    console.log("valuesvaluesvalues ", values);

    setIsloading(true);
    const response = await CarryoverAccountantRequest({ codes: [code], type: type, isAll: 0, s: values });
    console.log("responseresponseresponseresponse ", response);

    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      getListGroupAccountant();
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }

  const carryoverMultiple = async (code, type) => {
    const values = formSearchRef.current.getFieldsValue();
    if (values.month) {
      values.month = values.month.format("YYYY-MM")
    }
    if (values.precious) {
      const quarter = moment(values.precious).quarter();
      const year = moment(values.precious).year();
      values.precious = quarter;
      values.year = year;

    }

    if (!values.precious || values.month) {
      message.warning("Vui lòng chọn khoảng thời gian!");
      return;
    }
    setIsloading(true);
    const response = await CarryoverAccountantRequest({ codes: [-1], type: type, isAll: 1, s: values });
    console.log("responseresponseresponseresponse ", response);

    setIsloading(false);
    if (response.code == 200) {
      if (response.message) {
        message.success(response.message);
      } else {
        message.success("Cập nhật thành công");
      }
      getListGroupAccountant();
    } else {
      if (response.message) {
        if (typeof response.message == "string") {
          message.error(response.message);
        } else {
          (Object.values(response.message) || []).map((value, index) => {
            message.error(value);
          })
        }

      } else {
        message.warning("Cập nhật không thành công");
      }
    }
  }
  return (
    <div className='row w-100 bg-white p-2'
    // onClick={()=>setIsEdit(-1)}

    >
      <div className='col-lg-12 col-md-12 col-sm-12 d-flex'>
        <Link to={LINK.WEB_ACCOUNTANT}><Button><i className="fas fa-chevron-left"></i></Button></Link>
        <h4> Bảng cân đối</h4>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <Form name="formsearch" ref={formSearchRef} onFinish={onSearch} style={{
          padding: "0px",
          width: "100%",
          display: "flex"
        }}>

          <Form.Item name="month" noStyle >
            <DatePicker picker="month" style={{ width: "100%" }} placeholder='Chọn tháng' />
          </Form.Item>
          <Form.Item name="precious" noStyle >
            <DatePicker picker="quarter" style={{ width: "100%" }} placeholder='Chọn quý' />
            {/* <Select
              // multiple
              // mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Quý "
              options={DATA_PRECIOUS}
            /> */}
          </Form.Item>

          {/* <Form.Item name="year" noStyle >
            <DatePicker picker="year" style={{ width: "100%" }}  placeholder='Chọn năm' />
          </Form.Item> */}
          <Form.Item name="isAll" noStyle initialValue={1}>
            <Select
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="tất cả"
              options={[{ value: 1, label: "Chỉ phát sinh" }, { value: 2, label: "Tất cả tk" }]}
            />
          </Form.Item>

          {/* <Form.Item name="account" noStyle >
            <TreeSelect
            multiple
              mode="multiple"
              style={{ width: "100%" }}
              maxTagCount='responsive'
              placeholder="Tài khoản"
              treeData={[...listAccount]}
            />
          </Form.Item>
          <Form.Item name="description" noStyle>

            <Input placeholder="Diễn giải/ số phiếu" />
          </Form.Item>
          <Form.Item name="money" noStyle>

            <Input placeholder="số tiền" />
          </Form.Item> */}
          <Form.Item noStyle style={{
            width: "20%"
          }}>
            <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={isLoadingSearch} />
            <Button className='bg-warning' htmlType="button" style={{ width: "20%" }} loading={isLoadingSearch} onClick={resetFormSearch}>reset</Button>
          </Form.Item>

        </Form>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <div className="table-responsive" style={{ height: "700px" }}>
          <Form ref={formRef}>
            <table className="table table-ledger table-sm table-hover">
              <thead style={{ position: "sticky", top: 0 }}>
                <tr>
                  <th className='p-1 text-center align-middle' rowSpan={2} width="5%">STT</th>
                  <th className='p-1 text-center align-middle' rowSpan={2} width="5%">SHTK</th>
                  <th className='p-1 text-center align-middle' rowSpan={2} width="20%">Tên TK</th>
                  <th className='p-1 text-center align-middle' colSpan={2} width="20%" >Dư Đầu Kỳ</th>
                  <th className='p-1 text-center align-middle' colSpan={2} width="20%">Phát Sinh</th>
                  <th className='p-1 text-center align-middle' colSpan={2} width="20%">Dư Cuối Kỳ</th>


                </tr>
                <tr>
                  <th className='p-1 text-center align-middle' width="200px"  >Nợ</th>
                  <th className='p-1 text-center align-middle' width="200px" >Có</th>
                  <th className='p-1 text-center align-middle' width="200px"  >Nợ</th>
                  <th className='p-1 text-center align-middle' width="200px" >Có</th>
                  <th className='p-1 text-center align-middle' width="200px"  >Nợ</th>
                  <th className='p-1 text-center align-middle' width="200px" >Có</th>
                </tr>

              </thead>
              <tbody>
                {!data && <tr>
                  <td colSpan={9}>   <Skeleton />  </td>

                </tr>}
                {data && data.length <= 0 && <tr>
                  <td colSpan={9}>      <Empty />  </td>

                </tr>}

                {data && data.map((row, index) => {
                  let color = '';
                  let checkTran = false;
                  if ([3, 4, 5].includes(parseInt(row.account_group)) && (row.final_debit_balance > 0 || row.final_credit_balance > 0)) {
                    color = "bg-warning";
                  }
                  if ([3, 4].includes(parseInt(row.account_group)) && (row.final_debit_balance > 0 || row.final_credit_balance > 0)) {

                    checkTran = true;
                  }


                  return (
                    <tr className={`position-relative ${color}`} key={index}>

                      <td className='text-center'>{index + 1}</td>
                      <td className='text-center'>{row.account_code}</td>
                      <td className='text-left'>{row.account_description}</td>
                      <td className='text-right'>{formatNumber(row.debit_open_balance)}</td>
                      <td className='text-right'>{formatNumber(row.credit_open_balance)}</td>

                      <td className='text-right'>{formatNumber(row.debit_period_balance)}</td>

                      <td className='text-right'>{formatNumber(row.credit_period_balance)}</td>

                      <td className='text-right'>{formatNumber(row.final_debit_balance)}</td>
                      <td className='text-right position-ralative'>{formatNumber(row.final_credit_balance)}

                        {((row.account_group == 3 || row.account_group == 4) && (row.final_debit_balance > 0 || row.final_credit_balance > 0)) && <div className='popover-custom d-flex p-2'>
                          <Button size='small' htmlType='button' onClick={() => { carryover(row.account_code, 911) }} >Kết chuyển sang 911</Button>


                        </div>}
                        {(row.account_group == 5 && (row.final_debit_balance > 0 || row.final_credit_balance > 0) && !checkTran) && <div className='popover-custom d-flex p-2'>
                          <Button size='small' htmlType='button' onClick={() => { carryover(row.account_code, 4212) }} >Kết chuyển sang 4212</Button>


                        </div>}
                      </td>

                    </tr>
                  );
                })}
                {dataTotal && <tr className='bg-info ' style={{ position: "sticky", bottom: "0" }}>

                  <td className='text-center fw-bold  text-white' colSpan={3}>Tổng</td>

                  <td className='text-right fw-bold text-white'>{formatNumber(dataTotal.totalDebitOpenBalance)}</td>
                  <td className='text-right fw-bold  text-white'>{formatNumber(dataTotal.totalCreditOpenBalance)}</td>

                  <td className='text-right fw-bold  text-white'>{formatNumber(dataTotal.totalDebitPeriodBalance)}</td>

                  <td className='text-right fw-bold  text-white'>{formatNumber(dataTotal.totalCreditPeriodBalance)}</td>

                  <td className='text-right fw-bold  text-white' >{formatNumber(dataTotal.totalFinalDebitBalance)}</td>
                  <td className='text-right fw-bold  text-white'>{formatNumber(dataTotal.totalFinalCreditBalance)}</td>

                </tr>
                }

              </tbody>
            </table>

          </Form>
        </div>
      </div>

      <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between'>
        <div className='d-flex'>
        {!isClose &&  <Button className='bg-secondary text-white' onClick={() => carryoverMultiple(null, 911)}>Kết chuyển doanh thu / chi phí sang 911</Button>
        // <Button className='bg-secondary text-white' onClick={closeBalance}>Kết chuyển 911 sang 4212</Button> 
              }
        </div>
        {!isClose &&  <Button onClick={closeBalance} className='bg-danger text-white'>Chốt Sổ</Button>}
      </div>

    </div>
  )
}

export default Index

